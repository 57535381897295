import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEye, IconPencilPlus, IconTrash } from "@tabler/icons-react";
import React, { useState } from "react";
import Notification from "../Lib/Notification";
import fetchApi from "../Lib/api";
import Markdown from "../Lib/Markdown";
import styled from "styled-components";
import FeatureButton from "../../stories/FeatureButton";
import roleIcon from "../../stories/assets/roadmap-icon.svg";
import CreateRoleImage from "../../stories/assets/create-role.png";
import FeatureCard from "../../stories/FeatureCard";
import useStartupStore from "../../stores/startupStore";
import { useTranslation } from "react-i18next";

// Styled Components
const OpenRolesContainer = styled.div`
  background-color: var(--color-container-card-default);
  padding: 20px;
  border: 1px solid #dee8e7;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const FeatureTitle = styled.h3`
  color: var(--color-background-card);
  font-weight: var(--font-weight-h4);
  line-height: 18px;
`;

const RolesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RoleItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const RoleIcon = styled.img`
  width: 28px;
  height: 28px;
  background-color: var(--color-button-accent);
  padding: 5px;
  border-radius: 4px;
`;

const RoleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: 24px;
  width: 100%;
`;

const RoleHeader = styled.div`
  display: flex;
  align-items: center;
`;

const RoleTitle = styled.h4`
  color: var(--color-background-card);
  font-weight: 500;
`;

const RoleStatus = styled.span`
  background-color: var(--color-background-card);
  color: var(--color-background-light);
  font-size: var(--font-size-body-1);
  padding: 2px;
  border-radius: 4px;
  margin-left: 10px;
`;

export default function OpeningRoles({}) {
  const [opened, { open, close }] = useDisclosure(false);
  const [userRole, setUserRole] = useState("");
  const [status, setStatus] = useState("");
  const [requirements, setRequirements] = useState("");
  const [notification, setNotification] = useState(null);
  const { t } = useTranslation();

  const { isOwner, id, role, setOpenings, removeOpenings, openings } =
    useStartupStore((state) => ({
      id: state.slug,
      isOwner: state.isOwner,
      role: state.role,
      openings: state.openings,
      setOpenings: state.setOpenings,
      removeOpenings: state.removeOpenings,
    }));

  const handleCreate = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${id}/openings`,
        "POST",
        {
          opening: {
            role: userRole,
            status,
            requirements,
          },
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setNotification({ type: "success", content: res.message });
        setOpenings(res.opening);
        setUserRole("");
        setStatus("");
        setRequirements("");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  const handleDelete = async (roleId) => {
    try {
      const response = await fetchApi(
        `/startup_entities/${id}/openings/${roleId}`,
        "Delete"
      );

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        removeOpenings(res.opening.id);
      } else {
        const msg = (await response.json()).error;

        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };
  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          size="lg"
          onClose={close}
          withCloseButton={false}
        >
          {notification && <Notification {...notification} />}
          <Stack spacing={18} className="p-3">
            <TextInput
              label={t("startups_page.roles.m_r_title")}
              value={userRole}
              description={t("startups_page.roles.m_r_desc")}
              placeholder="e.g., Backend Developer"
              onChange={(event) => setUserRole(event.currentTarget.value)}
            />
            <Select
              label={t("startups_page.roles.m_s_title")}
              data={[
                "Founding Team",
                "Contributor",
                "Intern/Volunteer",
                "Consultant",
                "Investor",
              ]}
              value={status}
              description={t("startups_page.roles.m_s_desc")}
              placeholder="e.g., Co-founder"
              onChange={setStatus}
            />
            <Stack spacing={4}>
              <Title order={6} fw={300}>
                {t("startups_page.roles.m_requirements")}
              </Title>
              <Markdown
                value={requirements}
                placeholder={t("startups_page.roles.m_req_placeholder")}
                visibleDragbar={false}
                preview={"edit"}
                hideToolbar={false}
                height={300}
                setValue={(e) => {
                  setRequirements(e);
                }}
              />
            </Stack>

            {notification ? (
              <Button
                onClick={() => {
                  close();
                  setNotification(null);
                }}
                variant="default"
                fullWidth
                className="w-full px-12"
              >
                {t("startups_page.roles.m_continue")}
              </Button>
            ) : (
              <Button
                onClick={handleCreate}
                variant="default"
                fullWidth
                className="w-full px-12"
                disabled={!(userRole && status)}
              >
                {t("startups_page.roles.m_add_role")}
              </Button>
            )}
          </Stack>
        </Modal>
      )}

      {openings.length ? (
        <OpenRolesContainer>
          <FeatureTitle>{t("startups_page.roles.header")}</FeatureTitle>

          <RolesList>
            {openings.map((op) => (
              <Openings
                key={op.id}
                startupId={id}
                op={op}
                handleDelete={handleDelete}
                canAddRole={isOwner || role < 2}
              />
            ))}
          </RolesList>
          {(isOwner || role < 2) && (
            <FeatureButton
              buttonText={t("startups_page.roles.create")}
              action={open}
            />
          )}
        </OpenRolesContainer>
      ) : (
        <FeatureCard
          title={
            isOwner || role < 2
              ? t("startups_page.roles.header_n")
              : t("startups_page.roles.no_roles")
          }
          description={t("startups_page.roles.no_roles_desc")}
          imageUrl={CreateRoleImage}
          buttonText={t("startups_page.roles.create")}
          action={isOwner || role < 2 ? open : null}
        />
      )}
    </>
  );
}

function Openings({ startupId, op, handleDelete, canAddRole }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [notification, setNotification] = useState(null);
  const { t } = useTranslation();

  return (
    <RoleItem>
      {opened && (
        <Modal
          opened={opened}
          size="lg"
          onClose={close}
          withCloseButton={false}
        >
          {notification && <Notification {...notification} />}
          <Title order={5} fw={400}>
            {t("startups_page.roles.m_requirements")}
          </Title>

          <Markdown
            value={op.requirements}
            visibleDragbar={false}
            preview={"preview"}
            hideToolbar={true}
            setValue={(e) => {}}
            height={200}
          />

          {!!canAddRole && (
            <ApplyForRole
              setNotification={setNotification}
              status={op.status}
              openingId={op.id}
              notification={notification}
              startupId={startupId}
            />
          )}
        </Modal>
      )}
      <RoleIcon src={roleIcon} alt="Role Icon" />
      <RoleContent>
        <RoleHeader>
          <RoleTitle>{op.role}</RoleTitle>
          <RoleStatus>{op.status}</RoleStatus>
          <div style={{ marginLeft: "auto", display: "flex" }} spacing={8}>
            <ActionIcon
              size={25}
              variant="default"
              aria-label="Edit opening roles"
              onClick={open}
            >
              <IconEye style={{ width: rem(17), height: rem(17) }} />
            </ActionIcon>
            {canAddRole && (
              <ActionIcon
                size={25}
                variant="default"
                aria-label="Edit opening roles"
                onClick={() => handleDelete(op.id)}
              >
                <IconTrash style={{ width: rem(17), height: rem(17) }} />
              </ActionIcon>
            )}
          </div>
        </RoleHeader>
        <p style={{ fontSize: "var(--font-size-body-1)" }}>{op.requirements}</p>
      </RoleContent>
    </RoleItem>
  );
}

const ApplyForRole = ({
  startupId,
  openingId,
  setNotification,
  notification,
  status,
}) => {
  const [opened, { toggle }] = useDisclosure(false);
  const [reason, setReason] = useState("");
  const { t } = useTranslation();

  const handleInvite = async () => {
    try {
      const response = await fetchApi(
        `/startup_entities/${startupId}/join_requests/create_for_opening`,
        "POST",
        {
          opening_id: openingId,
          join_request: { reason, role: status },
        }
      );

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("Request sent successful!");
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Request failed.");
      }
    } catch (error) {
      console.error("Error during Sending request", error);
    }
  };
  return (
    <>
      <Group position="right" mt={10}>
        <Button variant="outline" onClick={toggle}>
          {t("startups_page.roles.m_a_apply")}
        </Button>
      </Group>
      <Collapse in={opened}>
        <Stack className="mt-4">
          <Textarea
            label={t("startups_page.roles.m_a_reason")}
            placeholder="Hello..."
            autosize
            minRows={3}
            maxRows={5}
            value={reason}
            onChange={(event) => setReason(event.currentTarget.value)}
          />
          <Select
            label={t("startups_page.roles.m_a_role")}
            data={[
              "Founding Team",
              "Contributor",
              "Intern/Volunteer",
              "Consultant",
              "Investor",
            ]}
            value={status}
            disabled
            description={t("startups_page.roles.m_a_role_desc")}
            placeholder="e.g., Co-founder"
            withinPortal={true}
          />
          {!notification ? (
            <Button
              onClick={handleInvite}
              variant="default"
              fullWidth
              className="w-full px-12"
              disabled={!reason}
            >
              {t("startups_page.roles.m_a_request")}
            </Button>
          ) : (
            <Button
              onClick={() => {
                setNotification(null);
                close();
              }}
              variant="default"
              fullWidth
              className="w-full px-12"
              disabled={!reason}
            >
              {t("startups_page.roles.m_a_close")}
            </Button>
          )}
        </Stack>
      </Collapse>
    </>
  );
};
