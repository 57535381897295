import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import HelpIcon from "./assets/help.svg"; 
import AccountIcon from "./assets/settings.svg";

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: unset;
  padding: 20px;
  margin: 80px auto;
  width: 100%;
`;

const FooterLeft = styled.div`
  display: flex;
`;

const FooterRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div {
    display: flex;
    justify-content: space-around;
  }

`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    text-decoration: none;
    color: var(--color-background-main);

    &:hover {
      color: #6c45e7;
    }
  }
`;

const HelpSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HelpItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .help-text p {
    font-weight: var(--font-weight-h5);
  }

  .help-text a {
    color: var(--color-background-main);

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const FooterRights = styled.p`
  font-size: 12px;
  color: #48504b;
  margin-top: 10px;
  margin-inline: auto;
`;

const StartupFooter = () => {
  return (
    <Footer>
      <FooterLeft>
        <div className="logo-section">
          <Logo />
        </div>
      </FooterLeft>

      <FooterRight>
        <div>
          <FooterLinks>
            <a href="#">About</a>
            <a href="#">Privacy & Terms</a>
            <a href="#">Privacy & Terms</a>
          </FooterLinks>

          <HelpSection>
            <HelpItem>
              <FooterIcon src={HelpIcon} alt="Help" />
              <div className="help-text">
                <p>Queries in mind?</p>
                <a href="#">Check out our Help Center or reach out for support tailored to your needs.</a>
              </div>
            </HelpItem>

            <HelpItem>
              <FooterIcon src={AccountIcon} alt="Account" />
              <div className="help-text">
                <p>Account & Privacy</p>
                <a href="#">Update your settings and manage your account details here.</a>
              </div>
            </HelpItem>
          </HelpSection>
        </div>

        <FooterRights>© 2025 Nowcollab. All rights reserved.</FooterRights>
      </FooterRight>
    </Footer>
  );
};

export default StartupFooter;
