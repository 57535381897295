import React, { useState, useEffect, useRef } from "react";
import { useForm } from "@mantine/form";
import { countriesData, industriesData } from "../components/Onboarding/data";
import fetchApi from "../components/Lib/api";
import { cleanText } from "../components/Lib/profanityCleaner";
import styled, { keyframes } from "styled-components";
import { DatePicker } from "@mantine/dates";

const morphRotate = keyframes`
  0% {
    border-radius: 20%;
    transform: rotate(0deg);
  }
  50% {
    border-radius: 50%;
    transform: rotate(180deg);
  }
  100% {
    border-radius: 20%;
    transform: rotate(360deg);
  }
`;

// Styled component for the loader
const LoaderS = styled.div`
  width: 80px;
  height: 80px;
  background-color: var(--color-button-accent);
  margin: 0 auto;
  animation: ${morphRotate} 2s infinite;
`;

// Styled container for the loading screen
const Container = styled.div`
  text-align: center;
  padding: 2rem;
`;

// Styled component for the live update message
const Message = styled.p`
  margin-top: 1.5rem;
`;

// Base Styled Components
const FormContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
`;

const HeaderContent = styled.div`
  flex: 1;
`;

const Caption = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4338ca;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${(props) => (props.darkMode ? "white" : "#1E1B4B")};
  margin: 0;
  margin-bottom: 0.75rem;
  font-weight: 600;
`;

const Description = styled.p`
  color: ${(props) => (props.darkMode ? "#666" : "#6B7280")};
  font-size: 14px;
  margin: 0;
  margin-bottom: 2rem;
`;


// Form Fields Components (Step 2)
const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${(props) => (props.darkMode ? "white" : "#1E1B4B")};
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  color: #6b7280;
  width: 100%;

  &::placeholder {
    color: #9ca3af;
  }
`;

const StyledTextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  color: #6b7280;
  width: 100%;
  background: transparent;

  &::placeholder {
    color: #9ca3af;
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  color: #6b7280;
  width: 100%;
  background: white;
  appearance: none;
  background-image: url("path-to-your-chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 1rem center;
`;

// Security Cards Components (Step 4)
const SecurityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
`;

const SecurityCard = styled.div`
  background: #f3f4f6;
  border: 1px solid ${(props) => (props.selected ? "#4338CA" : "transparent")};
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: #4338ca;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;

  img {
    width: 20px;
    height: 20px;
  }
`;

const CardTitle = styled.h3`
  color: #1e1b4b;
  font-size: 16px;
  margin: 0;
`;

const CardDescription = styled.p`
  font-size: 14px;
  margin: 0;
  line-height: 1.5;
`;

// Pricing Components (Step 5)
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid ${(props) => (props.darkMode ? "#333" : "#E5E7EB")};
`;

const StepCounter = styled.span`
  color: ${(props) => (props.darkMode ? "#666" : "#6B7280")};
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  ${(props) =>
    props.variant === "primary"
      ? `
    background: #4338CA;
    color: white;
    border: none;
    
    &:hover {
      background: #3730A3;
    }
  `
      : `
    background: transparent;
    color: #4338CA;
    border: none;
    
    &:hover {
      background: #F8FAFC;
    }
  `}
`;

const PricingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: start;
`;

const EmailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CheckboxGroup = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  margin-top: 1rem;
`;

const Checkbox = styled.input`
  cursor: pointer;
`;

const CheckboxLabel = styled.span`
  color: #666;
  font-size: 14px;
`;
styled.span`
  color: #666;
  font-size: 14px;
`;

const HelperText = styled.p`
  color: #666;
  font-size: 14px;
  margin: 1rem 0;
  line-height: 1.5;
`;

export const LoadingScreen = () => {
  const messages = [
    "We are setting up your workspace...",
    "Your data is being optimized...",
    "Almost there...",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessageIndex((prevIndex) =>
        prevIndex === messages.length - 1 ? 0 : prevIndex + 1
      );
    }, 30000);

    return () => clearInterval(timer);
  }, [messages.length]);

  return (
    <Container>
      <LoaderS />
      <Message>{messages[currentMessageIndex]}</Message>
    </Container>
  );
};

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1); // Start directly from step 1
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [roadmapChunks, setRoadmapChunks] = useState([]);

  // A ref attached to the container of the current step so we can auto-focus its first input.
  const stepContainerRef = useRef(null);

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      industry: "",
      stage: "",
      project_type: "",
      country: "",
      city: "",
      website: "",
      linkedin: "",
      social_media: "",
      public_listed: true,
      start_date: null,
      end_date: null,
    },
    // Basic validations defined here for immediate feedback (plus extra URL checks)
    validate: {
      name: (value) =>
        !value || value.trim() === "" ? "Name is required" : null,
      description: (value) =>
        !value || value.trim() === "" ? "Description is required" : null,
      industry: (value) => (!value ? "Industry is required" : null),
      country: (value) => (!value ? "Country is required" : null),
      city: (value) =>
        !value || value.trim() === "" ? "City is required" : null,
      website: (value) => {
        if (value && !/^https?:\/\//i.test(value)) {
          return "Please enter a valid URL (starting with http:// or https://)";
        }
        return null;
      },
      linkedin: (value) => {
        if (value && !/^https?:\/\//i.test(value)) {
          return "Please enter a valid URL (starting with http:// or https://)";
        }
        return null;
      },
      social_media: (value) => {
        if (value && !/^https?:\/\//i.test(value)) {
          return "Please enter a valid URL (starting with http:// or https://)";
        }
        return null;
      },
    },
  });

  // When the current step changes, try to focus the first input/select/textarea element.
  useEffect(() => {
    if (stepContainerRef.current) {
      const focusable = stepContainerRef.current.querySelector(
        "input, select, textarea"
      );
      if (focusable) {
        focusable.focus();
      }
    }
  }, [currentStep]);

  // Define which fields are required on each step.
  // Step 6 (social links) is optional.
  const requiredFields = {
    1: ["name"],
    2: ["description"],
    3: ["stage"],
    4: ["start_date", "end_date"],
    5: ["industry", "country", "city"],
    6: [], // Social links are optional
  };

  // Validate the fields for the current step.
  const validateCurrentStep = () => {
    let isValid = true;
    const fields = requiredFields[currentStep] || [];

    // Clear previous errors for these fields.
    fields.forEach((field) => form.setFieldError(field, null));

    fields.forEach((field) => {
      const value = form.values[field];

      if (field === "start_date" || field === "end_date") {
        if (!value) {
          form.setFieldError(field, "This field is required");
          isValid = false;
        }
      } else if (typeof value === "string" && value.trim() === "") {
        form.setFieldError(field, "This field is required");
        isValid = false;
      } else if (!value) {
        form.setFieldError(field, "This field is required");
        isValid = false;
      }
    });

    // Extra validation for dates (step 4)
    if (currentStep === 4) {
      const { start_date, end_date } = form.values;
      if (start_date && end_date) {
        // Check that end_date is at least 7 days after start_date.
        const diffTime = new Date(end_date) - new Date(start_date);
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (diffDays < 7) {
          form.setFieldError(
            "end_date",
            "End date must be at least 7 days after start date"
          );
          isValid = false;
        }
      }
    }

    if (!isValid) {
      setNotification({
        type: "failure",
        content: "Please fill all required fields before continuing.",
      });
    }
    return isValid;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setCurrentStep(7);
    try {
      const response = await fetchApi("/startup_entities", "POST", {
        startup_entity: {
          ...form.values,
          description: cleanText(form.values.description),
        },
      });

      if (response.ok) {
        const res = await response.json();

        // Store roadmap_payload in sessionStorage
        sessionStorage.setItem(
          "roadmap_payload",
          JSON.stringify(res.roadmap_payload)
        );

        // Redirect to new page
        window.location.assign(res.redirect_path);
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        setLoading(false);
        setCurrentStep(6);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      setNotification({ type: "failure", content: "An error occurred" });
      setLoading(false);
      setCurrentStep(6);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNext();
    }
  };

  const handleNext = () => {
    if (currentStep < 6) {
      if (!validateCurrentStep()) {
        return;
      }
      // Clear any notification once the current step is valid.
      setNotification(null);
      setCurrentStep((prev) => prev + 1);
    } else {
      // For step 6, submit the form directly (social links are optional)
      handleSubmit();
    }
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const renderStepOne = () => (
    <>
      <HeaderContainer>
        <HeaderContent>
          <Title>Name Your Vision</Title>
          <Description>
            This helps us understand your focus and set the foundation.
          </Description>
        </HeaderContent>
      </HeaderContainer>
      <FormGrid>
        <FormField style={{ gridColumn: "1 / -1" }}>
          <Label>What's your idea or project called?</Label>
          <Input
            type="text"
            placeholder="Revolutionizing Urban Mobility"
            {...form.getInputProps("name")}
            onKeyPress={handleKeyPress}
          />
          {form.errors.name && (
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {form.errors.name}
            </div>
          )}
        </FormField>
      </FormGrid>
    </>
  );

  const renderStepTwo = () => (
    <>
      <HeaderContainer>
        <HeaderContent>
          <Title>Describe It in a Sentence</Title>
          <Description>
            Keep it simple. We'll use this to generate tailored guides.
          </Description>
        </HeaderContent>
      </HeaderContainer>
      <FormGrid>
        <FormField style={{ gridColumn: "1 / -1" }}>
          <Label>What's your idea about?</Label>
          <StyledTextArea
            rows={5}
            placeholder="A platform connecting urban commuters with sustainable ridesharing solutions."
            {...form.getInputProps("description")}
            onKeyPress={handleKeyPress}
          />
          {form.errors.description && (
            <div style={{ color: "red", marginTop: "0.5rem" }}>
              {form.errors.description}
            </div>
          )}
        </FormField>
      </FormGrid>
    </>
  );

  const renderStepThree = () => (
    <>
      <HeaderContainer>
        <HeaderContent>
          <Title>What Stage Are You At?</Title>
          <Description>Select your current stage</Description>
        </HeaderContent>
      </HeaderContainer>
      <SecurityGrid>
        {[
          {
            stage: "Idea",
            description:
              "A stage where concepts are born and initial brainstorming begins.",
          },
          {
            stage: "Prototype",
            description:
              "Transforming ideas into tangible designs or working models.",
          },
          {
            stage: "Start-up",
            description:
              "The initial phase of launching a business and entering the market.",
          },
          {
            stage: "Project",
            description:
              "Focusing efforts on achieving specific objectives and milestones.",
          },
          {
            stage: "Growth",
            description:
              "Scaling up operations and expanding your impact or reach.",
          },
          {
            stage: "Maturity",
            description:
              "A stage where the focus shifts to stability and long-term success.",
          },
        ].map(({ stage, description }) => (
          <SecurityCard
            key={stage}
            selected={form.values.stage === stage}
            onClick={() => form.setFieldValue("stage", stage)}
          >
            <CardHeader>
              <CardTitle>{stage}</CardTitle>
            </CardHeader>
            <CardDescription>{description}</CardDescription>
          </SecurityCard>
        ))}
        {form.errors.stage && (
          <div style={{ color: "red", marginTop: "0.5rem" }}>
            {form.errors.stage}
          </div>
        )}
      </SecurityGrid>
    </>
  );

  const renderStepFour = () => (
    <>
      <HeaderContainer>
        <HeaderContent>
          <Title>When Will You Start?</Title>
          <Description>Select your timeline</Description>
        </HeaderContent>
      </HeaderContainer>
      <PricingContainer>
        <FormGrid>
          <FormField style={{ gridColumn: "1 / -1" }}>
            <Label>Start Date</Label>
            <DatePicker
              size="xl"
              value={form.values.start_date}
              onChange={(date) => {
                form.setFieldValue("start_date", date);
                // Auto-set end date to 7 days later if not already set
                if (!form.values.end_date) {
                  form.setFieldValue("end_date", addDays(date, 7));
                }
              }}
              minDate={new Date()}
              error={form.errors.start_date}
            />
          </FormField>
        </FormGrid>
        <FormGrid>
          <FormField style={{ gridColumn: "1 / -1" }}>
            <Label>End Date</Label>
            <DatePicker
              size="xl"
              value={form.values.end_date}
              onChange={(date) => form.setFieldValue("end_date", date)}
              minDate={
                form.values.start_date
                  ? addDays(form.values.start_date, 7)
                  : new Date()
              }
              error={form.errors.end_date}
              disabled={!form.values.start_date}
            />
          </FormField>
        </FormGrid>
        {form.errors.start_date && (
          <div style={{ color: "red", marginTop: "0.5rem" }}>
            {form.errors.start_date}
          </div>
        )}
      </PricingContainer>
    </>
  );

  const renderStepFive = () => (
    <>
      <HeaderContainer>
        <HeaderContent>
          <Title>Location Information</Title>
          <Description>
            This helps us tailor your journey with relevant guides, tools, and
            templates.
          </Description>
        </HeaderContent>
      </HeaderContainer>
      <EmailSection>
        <FormGrid>
          <FormField style={{ gridColumn: "1 / -1" }}>
            <Label>Industry</Label>
            <Select
              value={form.values.industry}
              onChange={(e) => form.setFieldValue("industry", e.target.value)}
              error={form.errors.industry}
            >
              <option value="">Select Industry...</option>
              {industriesData.map((industry) => (
                <option key={industry} value={industry}>
                  {industry}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField>
            <Label>Country</Label>
            <Select
              value={form.values.country}
              onChange={(e) => form.setFieldValue("country", e.target.value)}
              error={form.errors.country}
            >
              <option value="">Select Country...</option>
              {countriesData.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
          </FormField>
          <FormField>
            <Label>City</Label>
            <Input
              type="text"
              placeholder="Enter city"
              {...form.getInputProps("city")}
              onKeyPress={handleKeyPress}
              error={form.errors.city}
            />
          </FormField>
        </FormGrid>
        <CheckboxGroup>
          <Checkbox
            type="checkbox"
            checked={form.values.public_listed}
            onChange={(e) =>
              form.setFieldValue("public_listed", e.target.checked)
            }
          />
          <CheckboxLabel>Make it available to everyone</CheckboxLabel>
        </CheckboxGroup>
        <HelperText>
          When Private, Only Owners and Members can have access to it.
        </HelperText>
      </EmailSection>
    </>
  );

  const renderStepSix = () => (
    <>
      <HeaderContainer>
        <HeaderContent>
          <Title>Social Links (Optional)</Title>
          <Description>
            Add these for collaborators to explore your project better.
          </Description>
        </HeaderContent>
      </HeaderContainer>
      <EmailSection>
        <FormGrid>
          <FormField style={{ gridColumn: "1 / -1" }}>
            <Label>Website</Label>
            <Input
              type="text"
              placeholder="Enter website URL"
              {...form.getInputProps("website")}
              onKeyPress={handleKeyPress}
            />
            {form.errors.website && (
              <div style={{ color: "red", marginTop: "0.5rem" }}>
                {form.errors.website}
              </div>
            )}
          </FormField>
          <FormField>
            <Label>LinkedIn URL</Label>
            <Input
              type="text"
              placeholder="Enter LinkedIn profile"
              {...form.getInputProps("linkedin")}
              onKeyPress={handleKeyPress}
            />
            {form.errors.linkedin && (
              <div style={{ color: "red", marginTop: "0.5rem" }}>
                {form.errors.linkedin}
              </div>
            )}
          </FormField>
          <FormField>
            <Label>Social Media URL</Label>
            <Input
              type="text"
              placeholder="Enter social media profile"
              {...form.getInputProps("social_media")}
              onKeyPress={handleKeyPress}
            />
            {form.errors.social_media && (
              <div style={{ color: "red", marginTop: "0.5rem" }}>
                {form.errors.social_media}
              </div>
            )}
          </FormField>
        </FormGrid>
      </EmailSection>
    </>
  );

  const renderCurrentStep = () => {
    if (loading) {
      return <LoadingScreen roadmapChunks={roadmapChunks} />;
    }
    switch (currentStep) {
      case 1:
        return renderStepOne();
      case 2:
        return renderStepTwo();
      case 3:
        return renderStepThree();
      case 4:
        return renderStepFour();
      case 5:
        return renderStepFive();
      case 6:
        return renderStepSix();
      default:
        return null;
    }
  };

  return (
    <FormContainer>
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.content}
        </div>
      )}

      <Caption href="/dashboard/#/">Go back</Caption>

      {/* Wrap the current step with a container that has the ref attached */}
      <div style={{ minHeight: "50vh" }} ref={stepContainerRef}>
        {renderCurrentStep()}
      </div>

      <Footer>
        {!loading && (
          <>
            <StepCounter>{currentStep}/6</StepCounter>
            <ButtonGroup>
              {currentStep > 1 && (
                <Button onClick={() => setCurrentStep((prev) => prev - 1)}>
                  Back
                </Button>
              )}
              <Button variant="primary" onClick={handleNext} disabled={loading}>
                {currentStep === 6 ? "Submit" : "Next Step"}
              </Button>
            </ButtonGroup>
          </>
        )}
      </Footer>
    </FormContainer>
  );
};

export default MultiStepForm;
