import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce'; 
import styled from 'styled-components';
import SidebarAction from './SidebarAction';
import UserHeader from './UserHeader';
import StartupCards from './StartupCards';
import SearchTags from './SearchTags';

// Styled components
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LayoutRow = styled.div`
  display: grid;
  grid-template-columns: 240px 240px 32px repeat(12, minmax(0, 1fr)) 240px;
  grid-gap: 0px;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  padding-top: 70px;

  @media (max-width: 1600px) {
    grid-template-columns: 112px 240px 32px repeat(12, 1fr) 112px;
    max-width: 1600px;
  }

  @media (max-width: 1440px) {
    grid-template-columns: 32px 240px 32px repeat(12, 1fr) 32px;
    max-width: 1440px;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 12px 238px 32px repeat(12, minmax(0, 1fr)) 12px;
    max-width: 1280px;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 10px 183px 32px repeat(12, minmax(0, 1fr)) 10px;
    max-width: 1020px;
  }

  @media (max-width: 834px) {
    grid-template-columns: 32px repeat(12, minmax(0, 1fr)) 32px;
    max-width: 834px;
  }
`;

const SidebarLarge = styled.aside`
  grid-column: 2;
  text-align: center;
  position: relative;
  height: 100%;
  z-index: 90;

  @media (max-width: 834px) {
    display: none;
  }
`;

const SidebarSmall = styled.aside`
  display: none;
  position: fixed;
  top: 60px;
  left: -240px;
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 90;

  &.active {
    transform: translateX(240px);
  }

  @media (max-width: 834px) {
    display: block;
  }
`;

const SidebarCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 10px;
  cursor: pointer;
  color: white;
`;

const Content = styled.main`
  grid-column: 4 / span 12;

  @media (max-width: 834px) {
    grid-column: 2 / span 12;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const Layout = ({ header, sidebarContent, mainContent }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleMenuClick = () => {
    setIsSidebarVisible(!isSidebarVisible); // Toggle sidebar visibility for small screens
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(() => window.innerWidth > 834 && setIsSidebarVisible(false), 200);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return (
    <LayoutContainer>
      {/* Pass handleMenuClick to the header so it can trigger sidebar toggle */}
      {React.cloneElement(header || <></>, { onMenuClick: handleMenuClick })}

      {isSidebarVisible && <Overlay onClick={handleMenuClick} />}

      <LayoutRow>
        <SidebarLarge>
          {sidebarContent}
        </SidebarLarge>
        <Content>
          {mainContent}
        </Content>
      </LayoutRow>

      <SidebarSmall className={isSidebarVisible ? 'active' : ''}>
        <SidebarCloseButton onClick={handleMenuClick}>Close</SidebarCloseButton>
        {sidebarContent}
      </SidebarSmall>
    </LayoutContainer>
  );
};

export default Layout;
