import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  HoverCard,
  Menu,
  Text,
  TextInput,
  rem,
} from "@mantine/core";

import { IconInfoCircle, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import fetchApi from "../Lib/api";
import { IconPencil } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import moment from "moment";
import roadmapIcon from "../../stories/assets/roadmap-icon.svg";
import styled from "styled-components";
import Calendar from "react-calendar";
import useStartupStore from "../../stores/startupStore";

const RoadmapItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 0.3s ease;
  padding-right: 4px;
  position: relative;

  &:hover {
    background-color: #f0f0f0;
  }

  &:hover .icon-pencil {
    visibility: visible;
  }
`;

const IconPencilStyled = styled(IconPencil)`
  visibility: hidden; // Initially hidden
  cursor: pointer;
  width: 15px;
  height: 15px;
  transition: visibility 0.3s ease;
`;

const RoadmapIcon = styled.img`
  width: 24px;
  height: 24px;
  background-color: var(--color-button-accent);
  padding: 5px;
  border-radius: 4px;
  margin-top: 4px;
`;

const RoadmapInfo = styled.div`
  margin-left: 4px;
  white-space: nowrap;

  ${(props) =>
    props.collapsed &&
    `
    display: none;
  `}
`;

const RoadmapName = styled.span`
  cursor: pointer;
  font-weight: var(--font-weight-h3);
  font-size: 13px;
  color: var(--color-background-card);
`;

const RoadmapDate = styled.span`
  color: var(--color-background-main);
  font-size: var(--font-size-body-1);
`;

function PremiumAlert() {
  return (
    <HoverCard width={280} shadow="md">
      <HoverCard.Target>
        <IconInfoCircle size="1rem" color="green" />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="sm">
          These field is only available to premium members.{" "}
          <Anchor href="#">visit here for more information.</Anchor>
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const CategoryDate = ({ start_date, cId, member }) => {
  const [opened, setOpened] = useState(false);
  const [date, setDate] = useState(start_date);
  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);
  const [step, setStep] = useState(1); // Step to control calendar view (1: Start date, 2: End date)

  const handleUpdate = async () => {
    try {
      const response = await fetchApi(
        `/categories/update_dates/${cId}`,
        "PATCH",
        { category: { start_date: newStartDate, end_date: newEndDate } }
      );
      if (response.ok) {
        setDate(newStartDate); // Update the displayed date
      } else {
        const msg = (await response.json()).error;
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during date change:", error);
    }
  };

  return (
    <div className="flex items-start gap-4">
      <RoadmapDate>{moment(start_date).format("MMM Do, YYYY")}</RoadmapDate>
      {member && (
        <Menu
          shadow="md"
          opened={opened}
          onChange={setOpened}
          withinPortal
          position="right-start"
        >
          <Menu.Target>
            <IconPencilStyled className="icon-pencil" />
          </Menu.Target>
          <Menu.Dropdown p={20} className="max-w-80">
            {step === 1 && (
              <>
                <p>Start date</p>
                <Calendar value={newStartDate} onChange={setNewStartDate} />
                <Button
                  disabled={!newStartDate}
                  variant="default"
                  fullWidth
                  onClick={() => setStep(2)} // Move to the next calendar (end date)
                >
                  Next
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                <p>End date</p>
                <Calendar value={newEndDate} onChange={setNewEndDate} />
                <Button
                  disabled={!newEndDate}
                  variant="default"
                  fullWidth
                  onClick={() => {
                    handleUpdate();
                    setOpened(false); // Close the menu after update
                    setStep(1); // Reset to the first step
                  }}
                >
                  Update
                </Button>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      )}
    </div>
  );
};

export function CategoryWithSubcategories({
  category,
  index,
  setSelectedPrograms,
  owner,
  member,
  enableEdit,
  setCategory,
  isCollapsed,
}) {
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [switchToEdit, setSwitchToEdit] = useState(false);
  const [categoryName, setCategoryName] = useState(category.name);
  const [newSubcategoryNames, setNewSubcategoryNames] = useState([]);
  const [subcategoriesToDelete, setSubcategoriesToDelete] = useState([]);
  const [subcategoryNames, setSubcategoryNames] = useState([]);

  const { setEditorState } = useStartupStore((state) => ({
    setEditorState: state.setEditorState,
  }));

  useEffect(() => {
    // setEditorState();
  }, []);

  useEffect(() => {
    setSubcategoryNames(
      category.sub_categories.map((s) => ({
        name: s.name,
        response: s.response,
        id: s.id,
        privacy: s.privacy,
        is_active: s.is_active,
        is_editable: s.is_editable,
      }))
    );
  }, [category]);

  const handleAddNewField = () => {
    setNewSubcategoryNames((prevNewSubcategoryNames) => [
      ...prevNewSubcategoryNames,
      "",
    ]);
  };

  const handleRemoveNewField = (indexToRemove) => {
    setNewSubcategoryNames((prevNewSubcategoryNames) =>
      prevNewSubcategoryNames.filter((_, index) => index !== indexToRemove)
    );
  };

  const toggleSubcategories = () => {
    setSwitchToEdit(false);
    setShowSubcategories(!showSubcategories);
  };

  const handleSwitchingToEdit = () => {
    setShowSubcategories(true);
    setSwitchToEdit(!switchToEdit);
  };

  const handleCategoryNameChange = (event) => {
    console.log(event.target.value);
    setCategoryName(event.target.value);
  };

  const handleSubcategoryNameChange = useCallback(
    (index, event) => {
      console.log(index);
      setSubcategoryNames((prevSubcategoryNames) => {
        const newSubcategoryNames = [...prevSubcategoryNames];
        newSubcategoryNames[index] = {
          ...newSubcategoryNames[index],
          name: event.target.value,
        };
        return newSubcategoryNames;
      });
    },
    [subcategoryNames]
  );

  const handleNewSubcategoryNameChange = useCallback(
    (index, event) => {
      setNewSubcategoryNames((prevNewSubcategoryNames) => {
        const newSubcategoryNames = [...prevNewSubcategoryNames];
        newSubcategoryNames[index] = event.target.value;
        return newSubcategoryNames;
      });
    },
    [newSubcategoryNames]
  );

  const handleSubcategoryDelete = useCallback(
    (index) => {
      setSubcategoriesToDelete((prevSubcategoriesToDelete) => [
        ...prevSubcategoriesToDelete,
        subcategoryNames[index].id,
      ]);

      console.log(subcategoriesToDelete);

      setSubcategoryNames((prevSubcategoryNames) =>
        prevSubcategoryNames.filter((_, idx) => idx !== index)
      );
    },
    [subcategoryNames, setSubcategoriesToDelete, setSubcategoryNames]
  );

  const handleUpdate = async () => {
    const updatedData = {
      category: {
        name: categoryName,
        sub_categories_attributes: subcategoryNames.map(
          ({ is_active, is_editable, ...rest }) => ({
            ...rest,
          })
        ),
        new_sub_categories_attributes: newSubcategoryNames
          .filter((name) => name.trim() !== "")
          .map((name) => ({
            name,
          })),
        deleted_sub_category_ids: subcategoriesToDelete,
      },
    };

    try {
      const response = await fetchApi(
        `/categories/update_names/${category.id}`,
        "PATCH",
        updatedData
      );

      if (response.ok) {
        const res = await response.json();
        setSwitchToEdit(false);
        const updatedCategory = res.category;
        setCategory(updatedCategory);
        setNewSubcategoryNames([]);
        console.log(res);
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    } finally {
      setShowSubcategories(false);
    }
  };

  const timelineItems = useMemo(() => {
    const items = [];

    if (showSubcategories) {
      subcategoryNames.forEach((subcategory, idx) => {
        items.push(
          <li className="list-disc" key={subcategory.id + "subCategory"}>
            {enableEdit && switchToEdit ? (
              <div
                className={`${enableEdit ? "flex items-end gap-3" : "mb-4"}`}
              >
                <TextInput
                  defaultValue={subcategoryNames[idx].name}
                  size="xs"
                  onChange={(event) => handleSubcategoryNameChange(idx, event)}
                />
                <ActionIcon
                  href={`#`}
                  size={24}
                  variant="default"
                  aria-label="Delete subcategory"
                  onClick={() => handleSubcategoryDelete(idx)}
                >
                  <IconTrash
                    color="red"
                    style={{ width: rem(13), height: rem(13) }}
                  />
                </ActionIcon>
              </div>
            ) : (
              <p
                key={subcategory.id}
                size="sm"
                c={subcategory.is_active ? "" : "dimmed"}
                style={{ cursor: "pointer", marginTop: "4px" }}
                onClick={() =>
                  setSelectedPrograms((p) => ({
                    ...p,
                    cid: index,
                    sid: idx,
                    switch: true,
                  }))
                }
              >
                <Group spacing={1} noWrap align="center">
                  {subcategory.name}
                  {!subcategory.is_active && <PremiumAlert />}
                </Group>
              </p>
            )}
          </li>
        );
      });

      {
        enableEdit && switchToEdit && (
          <>
            {newSubcategoryNames.forEach((name, index) => {
              items.push(
                <div
                  key={"new-field" + index}
                  className="flex items-center gap-3"
                >
                  <TextInput
                    value={name}
                    size="xs"
                    onChange={(event) =>
                      handleNewSubcategoryNameChange(index, event)
                    }
                  />
                  <ActionIcon
                    href={`#`}
                    size={24}
                    variant="default"
                    aria-label="Delete subcategory"
                    onClick={() => handleRemoveNewField(index)}
                  >
                    <IconTrash
                      color="red"
                      style={{ width: rem(13), height: rem(13) }}
                    />
                  </ActionIcon>
                </div>
              );
            })}
          </>
        );
      }

      if (
        enableEdit &&
        switchToEdit &&
        newSubcategoryNames.length + subcategoryNames.length < 5
      )
        items.push(
          <button
            className="mt-2"
            key={"newfield-create"}
            onClick={handleAddNewField}
          >
            Create new field
          </button>
        );
    }

    return items;
  }, [
    newSubcategoryNames,
    subcategoryNames,
    category,
    enableEdit,
    showSubcategories,
    switchToEdit,
  ]);

  return (
    <RoadmapItem>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "8px",
          padding: "12px",
        }}
        key={index}
      >
        <RoadmapIcon src={roadmapIcon} alt="Roadmap Icon" />
        <div>
          {!isCollapsed && (
            <RoadmapInfo>
              {enableEdit && switchToEdit ? (
                <TextInput
                  value={categoryName}
                  size="xs"
                  onChange={handleCategoryNameChange}
                />
              ) : (
                <>
                  <RoadmapName
                    onClick={() => {
                      setSelectedPrograms({
                        cid: index,
                        sid: 0,
                        switch: true,
                      });
                      // setEditorState();
                      toggleSubcategories();
                    }}
                  >
                    {category.name}
                  </RoadmapName>
                  {category.start_date && (
                    <CategoryDate
                      member={member}
                      key={category.start_date}
                      start_date={category.start_date}
                      cId={category.id}
                    />
                  )}
                </>
              )}
            </RoadmapInfo>
          )}
          {!isCollapsed && <ul className="pl-4">{timelineItems}</ul>}
        </div>
      </div>
      {!isCollapsed && enableEdit && (
        <>
          {switchToEdit ? (
            <ActionIcon
              href={`#`}
              size={28}
              variant="default"
              aria-label="Edit page link"
              onClick={handleUpdate}
              mt={10}
            >
              <IconCheck
                style={{ width: rem(18), height: rem(18) }}
                stroke={2.5}
              />
            </ActionIcon>
          ) : (
            <ActionIcon
              href={`#`}
              size={28}
              variant="default"
              aria-label="Edit page link"
              onClick={handleSwitchingToEdit}
              mt={10}
            >
              <IconPencil style={{ width: rem(15), height: rem(15) }} />
            </ActionIcon>
          )}
        </>
      )}
    </RoadmapItem>
  );
}
