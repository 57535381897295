import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import fetchApi from "../Lib/api";
import { cleanText } from "../Lib/profanityCleaner";
import StartupCreate from "../../stories/StartupCreate";

const getSubdomain = () => {
  const hostname = window.location.hostname; // e.g., 'subdomain.example.com'
  const parts = hostname.split(".");

  // Assuming 'example.com' is your main domain
  if (parts.length > 2) {
    return parts[0]; // The subdomain is the first part
  }
  return null; // No subdomain found
};

export default function CreateStartup({ project }) {
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [isProject, setIsProject] = useState(false);
  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      industry: "",
      stage: "",
      project_type: "",
      country: "",
      city: "",
      website: "",
      linkedin: "",
      social_media: "",
      public_listed: true,
    },
    validate: {
      name: (value) => {
        if (!value) {
          return "Name is required";
        }
        return null;
      },
      description: (value) => {
        if (!value) {
          return "Description is required";
        }
        return null;
      },
      industry: (value) => {
        if (!value) {
          return "Industry is required";
        }
        return null;
      },
      country: (value) => {
        if (!value) {
          return "Country is required";
        }
        return null;
      },
      city: (value) => {
        if (!value) {
          return "City is required";
        }
        return null;
      },
    },
  });

  const subdomain = getSubdomain();

  useEffect(() => {
    if (project) return;

    // Get the current path
    const path = window.location.pathname;

    // Split the path and extract the last part (the token)
    const parts = path.split("/");
    const capturedToken = parts.pop() || parts.pop(); // Handle possible trailing slash

    setToken(capturedToken);
  }, []);

  const handleSubmit = async (e) => {
    const url = isProject ? "/project_entities" : "/startup_entities";
    const key = isProject ? "project_entity" : "startup_entity";
    setLoading(true);

    try {
      const response = await fetchApi("/startup_entities", "POST", {
        startup_entity: {
          ...form.values,

          description: cleanText(form.values.description),
        },
        token,
      });

      if (response.ok) {
        const res = (await response.json()).startup_entity;
        // setNotification({ type: "success", content: msg });
        console.log(res);
        window.location.href = `${url}/${res.slug}`;
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    } finally {
      setLoading(false);
    }
  };

  return <StartupCreate />;
}
