import React from "react";
import styled from "styled-components";
import FinancialIcon from "./assets/tags.svg";
// import CraftIcon from "./assets/craft.svg";
// import MusicIcon from "./assets/music.svg";
// import EnergyIcon from "./assets/energy.svg";
// import ResearchIcon from "./assets/research.svg";
// import DesignIcon from "./assets/design.svg";
// import SolutionsIcon from "./assets/solutions.svg";
// import HistoryIcon from "./assets/history.svg";

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 24px;
    padding: 10px 20px;
  font-size: 11px;
  color: #334155;
  font-weight: 500;
  white-space: nowrap;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e2e8f0;
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

const SearchTags = () => {
  const tags = [
    { icon: FinancialIcon, label: "Financial" },
    { icon: FinancialIcon, label: "Traditional Art & Craft" },
    { icon: FinancialIcon, label: "Traditional Classical Music" },
    { icon: FinancialIcon, label: "Renewable Energy" },
    { icon: FinancialIcon, label: "Startup Research" },
    { icon: FinancialIcon, label: "Industrial Design" },
    { icon: FinancialIcon, label: "Sustainable Solutions" },
    { icon: FinancialIcon, label: "Ancient History & Philosophy" },
  ];

  return (
    <TagsWrapper>
      {tags.map((tag, index) => (
        <Tag key={index}>
          <img src={tag.icon} alt={tag.label} />
          {tag.label}
        </Tag>
      ))}
    </TagsWrapper>
  );
};

export default SearchTags;
