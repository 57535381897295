// stores/startupStore.js
import { create } from "zustand";
import EditorJsDiff from "../components/Startups/EditorJsDiff";
import fetchApi from "../components/Lib/api";

function safeParse(value) {
  try {
    return typeof value === "string" && value !== null
      ? JSON.parse(value)
      : { blocks: [] };
  } catch {
    return { blocks: [] };
  }
}

const useStartupStore = create((set, get) => ({
  id: null,
  startup: null, // Holds the startup_entity
  program: null, // Holds the program related to the startup
  isOwner: false, // Whether the user is the owner of the startup
  isMember: false, // Whether the user is a member of the startup
  role: null, // The role of the user in the startup
  isFollowing: false, // Whether the user is following the startup
  existingJoinRequest: false, // Whether there is an existing join request
  editMode: false,
  members: [],
  openings: [],
  slug: "",
  entity: "Startup",
  userInitials: "AM",
  content: {}, // { [pageId]: { [index]: content } }
  privacy: {}, // { [pageId]: { [index]: privacy } }
  files: {}, // { [pageId]: { [index]: files } }
  pendingChanges: {},
  selectedProgram: {
    switch: false,
    cid: 0, // pageId
    sid: 0, // index
  },
  roadmapId: "",
  openChat: false,
  showFunding: false,
  startActivity: false,
  startTime: Date.now(),
  pendingJoinRequest: 0,
  setEditorState: () => {
    console.log("Initializing editor state");
    set((state) => {
      const categories = state.program?.categories || [];

      const updatedContent = {};
      const updatedPrivacy = {};
      const updatedFiles = {};

      categories.forEach((category, categoryIndex) => {
        const category_id = category.id;
        const subCategories = category.sub_categories || [];

        const pageContent = {};
        const pagePrivacy = {};
        const pageFiles = {};

        subCategories.forEach((subCategory, subIndex) => {
          pageContent[subIndex] = {
            response: subCategory.response,
            originalResponse: subCategory.response, // Include original response for diff
            diff: subCategory.diff || null, // Include diff from backend
            touched: false,
            id: subCategory.id,
            category_id,
            name: subCategory.name,
            guide: subCategory.guide,
            wait: subCategory.waiting_for_approval,
            chats: subCategory.limited_conversation_histories,
          };
          pagePrivacy[subIndex] = subCategory.privacy;
          pageFiles[subIndex] = []; // Initialize files array for this tab
        });

        updatedContent[categoryIndex] = pageContent;
        updatedPrivacy[categoryIndex] = pagePrivacy;
        updatedFiles[categoryIndex] = pageFiles;
      });

      return {
        content: updatedContent,
        privacy: updatedPrivacy,
        files: updatedFiles,
      };
    });
  },

  setContent: (response, guide) => {
    set((state) => {
      const categoryIndex = state.selectedProgram.cid;
      const subIndex = state.selectedProgram.sid;

      // Update content
      const updatedCategoryContent = {
        ...(state.content[categoryIndex] || {}),
        [subIndex]: {
          ...(state.content[categoryIndex]?.[subIndex] || {}),
          response:
            response || state.content[categoryIndex]?.[subIndex]?.response,
          guide: guide || state.content[categoryIndex]?.[subIndex]?.guide,
          touched: true,
          name: state.content[categoryIndex]?.[subIndex]?.name,
        },
      };

      // Track change
      const pendingChanges = {
        ...state.pendingChanges,
        [categoryIndex]: {
          ...(state.pendingChanges[categoryIndex] || {}),
          [subIndex]: {
            ...(state.pendingChanges[categoryIndex]?.[subIndex] || {}),
            content: true,
          },
        },
      };

      return {
        content: {
          ...state.content,
          [categoryIndex]: updatedCategoryContent,
        },
        pendingChanges,
      };
    });
  },

  setPrivacy: (c) => {
    set((state) => {
      const categoryIndex = state.selectedProgram.cid;
      const subIndex = state.selectedProgram.sid;

      // Update privacy
      const updatedCategoryPrivacy = {
        ...(state.privacy[categoryIndex] || {}),
        [subIndex]: c,
      };

      // Track change
      const pendingChanges = {
        ...state.pendingChanges,
        [categoryIndex]: {
          ...(state.pendingChanges[categoryIndex] || {}),
          [subIndex]: {
            ...(state.pendingChanges[categoryIndex]?.[subIndex] || {}),
            privacy: true,
          },
        },
      };

      return {
        privacy: {
          ...state.privacy,
          [categoryIndex]: updatedCategoryPrivacy,
        },
        pendingChanges,
      };
    });
  },

  setFiles: (newFiles) => {
    set((state) => {
      const categoryIndex = state.selectedProgram.cid;
      const subIndex = state.selectedProgram.sid;

      // Update files
      const updatedCategoryFiles = {
        ...(state.files[categoryIndex] || {}),
        [subIndex]: newFiles,
      };

      // Track change
      const pendingChanges = {
        ...state.pendingChanges,
        [categoryIndex]: {
          ...(state.pendingChanges[categoryIndex] || {}),
          [subIndex]: {
            ...(state.pendingChanges[categoryIndex]?.[subIndex] || {}),
            files: true,
          },
        },
      };

      return {
        files: {
          ...state.files,
          [categoryIndex]: updatedCategoryFiles,
        },
        pendingChanges,
      };
    });
  },

  setEditMode: (isOpened) => set({ editMode: isOpened }),
  setIsFollowing: (follow) => set({ isFollowing: follow }),

  // Setters for startup-related fields
  setStartup: async (data) => {
    console.log(data);
    set({
      startup: data.startup_entity,
      id: data.startup_entity.id,
      program: data.startup_entity.program
        ? {
            ...data.startup_entity.program,
            categories: data.startup_entity.program?.categories
              .map((category) => {
                // Filter sub_categories based on criteria
                const filteredSubCategories = category.sub_categories.filter(
                  (subCategory) =>
                    subCategory.is_active &&
                    (subCategory.privacy === "Everyone" ||
                      (subCategory.privacy === "Only me" && data.is_owner) ||
                      (subCategory.privacy === "Members" && data.is_member))
                );

                // Return the updated category with filtered sub_categories only if there's at least one sub_category
                return filteredSubCategories.length > 0
                  ? {
                      ...category,
                      sub_categories: filteredSubCategories,
                    }
                  : null;
              })
              .filter((category) => category !== null),
          }
        : { loading: true, categories: [] },
      isOwner: data.is_owner,
      slug: data.startup_entity.slug,
      isMember: data.is_member,
      role: data.role,
      isFollowing: data.is_following,
      existingJoinRequest: data.existing_join_request,
      openings: data.startup_entity.openings,
      members: data.startup_entity.members,
      entity: data.startup_entity.entity_type,
      pendingJoinRequest: data.pending_join_requests_count,
      roadmapId: data.startup_entity?.program?.roadmap_id,
    });

    if (data.startup_entity.program) {
      // Program exists, so initialize the editor state normally.
      get().setEditorState();
    } else {
      const storedPayload = sessionStorage.getItem("roadmap_payload");
      console.log(storedPayload);
      if (storedPayload) {
        try {
          const roadmapResponse = await fetch(
            "https://nowcollab.com/api/v1/roadmap/stream",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "User-Agent": "RoadmapClient/1.0",
              },
              body: storedPayload,
            }
          );

          if (roadmapResponse.ok) {
            const reader = roadmapResponse.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              const chunk = decoder.decode(value);
              try {
                const jsonChunk = JSON.parse(chunk);
                console.log(jsonChunk);
                get().appendCategoryToProgram(jsonChunk);
              } catch (parseError) {
                console.error("Error parsing JSON chunk:", parseError);
              }
            }

            // Refresh startup data
            try {
              const startupResponse = await fetchApi(
                `/startup_entities/${get().slug}`,
                "GET"
              );
              if (startupResponse.ok) {
                const startupData = await startupResponse.json();
                get().setStartup(startupData);
              }
            } catch (refreshError) {
              console.error("Error refreshing startup data:", refreshError);
            }

            // Process complete roadmap data after streaming
            // setRoadmapData(roadmapData);
          } else {
            console.error("Roadmap generation failed", roadmapResponse);
          }
        } catch (roadmapError) {
          console.error("Error generating roadmap:", roadmapError);
        }
      } else {
        set((state) => {
          return {
            program: {
              ...state.program,
              loading: false,
            },
          };
        });
      }
    }
  },

  setChats: (newChats) => {
    set((state) => {
      const categoryIndex = state.selectedProgram.cid;
      const subIndex = state.selectedProgram.sid;

      // Update the chats in the content state
      const updatedSubcategoryContent = {
        ...(state.content[categoryIndex]?.[subIndex] || {}),
        chats: newChats,
      };

      return {
        content: {
          ...state.content,
          [categoryIndex]: {
            ...(state.content[categoryIndex] || {}),
            [subIndex]: updatedSubcategoryContent,
          },
        },
      };
    });
  },

  setStartupImage: (image) =>
    set((state) => ({
      startup: { ...state.startup, image },
    })),

  setSelectedProgram: (s) =>
    set((state) => ({
      selectedProgram: { ...state.selectedProgram, ...s },
    })),

  setProgram: (s) =>
    set((state) => ({
      program: { ...state.program, ...s },
    })),

  setCategory: (cat) => {
    set((state) => ({
      program: {
        ...state.program,
        categories: state.program?.categories?.map((c) =>
          c.id == cat.id ? cat : c
        ),
      },
    }));
  },

  setSubcategory: (sub) => {
    set((state) => ({
      program: {
        ...state.program,
        categories: state.program?.categories?.map((c, i) =>
          i == state.selectedProgram.cid
            ? {
                ...c,
                sub_categories: c.sub_categories.map((s, i) =>
                  i == state.selectedProgram.sid ? sub : s
                ),
              }
            : c
        ),
      },
    }));
  },

  setOpenings: (s) =>
    set((state) => ({
      openings: [...state.openings, s],
    })),

  setMembers: (s) =>
    set((state) => ({
      members: [...state.members, s],
    })),

  removeMembers: (id) =>
    set((state) => ({
      members: state.members.filter((p) => p.id != id),
      isMember: false,
    })),

  removeOpenings: (id) =>
    set((state) => ({
      openings: state.openings.filter((p) => p.id != id),
    })),

  toggleOpenChat: () => set((state) => ({ openChat: !state.openChat })),
  toggleShowFunding: () =>
    set((state) => ({ showFunding: !state.showFunding })),

  logTime: async (slug) => {
    const { startTime } = get();
    const endTime = Date.now();
    const timeSpentInMinutes = (endTime - startTime) / 1000 / 60;

    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/log_time`,
        "POST",
        {
          time_spent: timeSpentInMinutes,
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log("Time logged successfully:", res);
      } else {
        const msg = (await response.json()).error;
        console.error("Failed to log time:", msg);
      }
    } catch (error) {
      console.error("Error logging time:", error);
    }
  },

  handleFileChange: async (event, slug) => {
    const selectedFile = event.target.files[0];
    const imageURL = URL.createObjectURL(selectedFile);
    set({ startupImage: imageURL });

    try {
      const formData = new FormData();
      formData.append("startup_entity[image]", selectedFile);
      const response = await fetchApi(
        `/startup_entities/${slug}`,
        "PATCH",
        formData,
        true
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("File updated successfully:", responseData);
      } else {
        console.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
    }
  },

  submitPendingChanges: async () => {
    const { pendingChanges, content, privacy, files, setStartup, slug } = get();

    // Early exit if no changes
    if (Object.keys(pendingChanges).length === 0) {
      console.log("No pending changes to submit.");
      return { success: true };
    }

    const differ = new EditorJsDiff();
    const changes = [];

    // Prepare changes
    for (const [categoryIndex, subChanges] of Object.entries(pendingChanges)) {
      for (const [subIndex, changeFlags] of Object.entries(subChanges)) {
        const categoryIdx = parseInt(categoryIndex);
        const subIdx = parseInt(subIndex);

        // Validate data
        const subcategoryContent = content[categoryIdx]?.[subIdx];
        const subcategoryPrivacy = privacy[categoryIdx]?.[subIdx];
        const subcategoryFiles = files[categoryIdx]?.[subIdx];

        if (!subcategoryContent || !subcategoryPrivacy || !subcategoryFiles) {
          console.error(
            `Missing data for category ${categoryIdx}, subcategory ${subIdx}`
          );
          continue;
        }

        const subcategoryId = subcategoryContent.id;
        const categoryId = subcategoryContent.category_id;

        const updatePayload = {
          categoryId,
          subcategoryId,
          updates: {},
        };

        // Add changes based on flags
        if (changeFlags.content) {
          const newResponse = subcategoryContent.response;
          updatePayload.updates.response = newResponse;
          updatePayload.updates.diff = differ.compareContent(
            subcategoryContent.originalResponse,
            newResponse
          );
        }

        if (changeFlags.privacy) {
          updatePayload.updates.privacy = subcategoryPrivacy;
        }

        if (changeFlags.files) {
          updatePayload.updates.files = subcategoryFiles;
        }

        changes.push(updatePayload);
      }
    }

    // Exit if no valid changes
    if (changes.length === 0) {
      console.log("No valid changes to submit.");
      return { success: true };
    }

    try {
      // Send changes to server
      const response = await fetchApi("/sub_categories/bulk_update", "PATCH", {
        changes,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || response.statusText);
      }

      // Clear pending changes
      set({ pendingChanges: {} });

      // Refresh startup data
      try {
        const startupResponse = await fetchApi(
          `/startup_entities/${slug}`,
          "GET"
        );
        if (startupResponse.ok) {
          const startupData = await startupResponse.json();
          setStartup(startupData);
        }
      } catch (refreshError) {
        console.error("Error refreshing startup data:", refreshError);
      }

      return {
        success: true,
        message: "Changes submitted successfully",
        changesCount: changes.length,
      };
    } catch (error) {
      console.error("Error submitting changes:", error.message);

      return {
        success: false,
        error: error.message,
        changesCount: changes.length,
      };
    }
  },

  appendCategoryToProgram: (chunk) => {
    const mappedCategory = {
      name: chunk.step_name,
      id: chunk.step_name,
      start_date: chunk.subcategories[0].start_date,
      sub_categories: chunk.subcategories.map((sub, index) => ({
        name: sub.name,
        id: index,
        privacy: "Everyone",
        response: {
          blocks: [{ type: "paragraph", data: { text: sub.outcome } }],
        },
        is_active: true,
        is_editable: true,
      })),
    };

    set((state) => {
      const existingCategories = state.program?.categories || [];
      return {
        program: {
          ...state.program,
          categories: [...existingCategories, mappedCategory],
        },
      };
    });
  },
}));

export default useStartupStore;
