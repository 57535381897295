import React, { useState, useEffect } from "react";
import fetchApi from "../Lib/api";
import TagsInput from "../Lib/TagsInput";

const CorporateSignUpForm = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    company_website: "",
    phone: "",
    contact_person: "",
    email: "",
    industry_sector: "",
    desired_expertise: "",
    project_description: "",
    terms_and_policy: false,
  });

  const [notification, setNotification] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);


  useEffect(() => {
    console.log("Corporate Signup Controller connected");
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleMultiSelectChange = (selected) => {
    setSelectedSectors(selected);
    setFormData((prevData) => ({
      ...prevData,
      industry_sector: selected.map((option) => option),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(key, value);
    }

    try {
      const response = await fetchApi("/corporate_signups", "POST", {
        corporate_signup: {
          ...formData,
          industry_sector: formData.industry_sector.join(", "),
        },
      });

      const data = await response.json();

      if (response.ok) {
        setNotification(
          <div role="alert">
            <p className="section-text text-green-700">{data.message}</p>
            <p className="section-text" style={{ marginTop: "3rem" }}>
              Thank you for joining NowCollab! We appreciate your interest and
              will review your information shortly. Our team will reach out to
              you within 3 business days with the next steps.
            </p>
            <p className="section-text">
              While you wait, feel free to connect with us on social media or
              check out some useful links.
            </p>
            <div className="flex justify-between mt-8">
              <ul className="social-list flex space-x-4">
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>
              </ul>
              <ul className="quicklink-list space-y-2 mt-4">
                <li>
                  <a href="#" className="quicklink">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        );
        setFormData({
          company_name: "",
          company_website: "",
          phone: "",
          contact_person: "",
          email: "",
          industry_sector: "",
          desired_expertise: "",
          project_description: "",
          terms_and_policy: false,
        });
        setSelectedSectors([]);
        setIsSubmitted(true);
      } else {
        setNotification(
          <div role="alert" className="text-red-700">
            <p className="section-text">
              {data.message ||
                "There was an error with the sign-up form. Please try again."}
            </p>
          </div>
        );
      }
    } catch (error) {
      setNotification(
        <div role="alert" className="text-red-700">
          <p className="section-text">
            An error occurred. Please try again later.
          </p>
        </div>
      );
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsSubmitted(false);
    setNotification("");
  };

  return (
    <>
      <button onClick={openModal} className="btn bg-green-500">
        Find Experts Now
      </button>

      {isModalOpen && (
        <div
          id="formModal"
          className="modal text-left shadow-sm fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-10"
          onClick={(e) => {
            if (e.target.id === "formModal") closeModal();
          }}
        >
          <div className="modal-content bg-white max-w-[370px] lg:max-w-[700px] rounded-lg relative mt-8 lg:mt-[10vh] ">
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="space-y-6 p-4 lg:p-16 shadow-md rounded-lg"
              >
                {isSubmitted ? (
                  <div id="notification_area">{notification}</div>
                ) : (
                  <>
                    {notification && !isSubmitted && (
                      <div id="notification_area">{notification}</div>
                    )}
                    <div className="text-center mb-12">
                      <h1 className="block text-2xl font-bold text-gray-800">
                        Business Sign-Up Form
                      </h1>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                      {/* Company Name Input */}
                      <div>
                        <label className="inline font-light text-md mb-1 leading-[1.4]">
                          Company Name
                          <span className="text-red-500 inline">*</span>
                        </label>
                        <input
                          type="text"
                          name="company_name"
                          value={formData.company_name}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>

                      {/* Company Website Input */}
                      <div>
                        <label className="inline font-light text-md mb-1 leading-[1.4]">
                          Company Website
                        </label>
                        <input
                          type="url"
                          name="company_website"
                          value={formData.company_website}
                          onChange={handleChange}
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>

                      {/* Phone Number Input */}
                      <div>
                        <label className="inline font-light text-md mb-1 leading-[1.4]">
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>
                    </div>

                    {/* Contact Person's Full Name Input */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Contact Person's Full Name
                        <span className="text-red-500 inline">*</span>
                      </label>
                      <input
                        type="text"
                        name="contact_person"
                        value={formData.contact_person}
                        onChange={handleChange}
                        required
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                      <p className="mt-1 text-[11px] text-gray-500">
                        Full name of the user applying to sign in or sign up
                        (e.g., Sam John).
                      </p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      {/* Email Address Input */}
                      <div>
                        <label className="inline font-light text-md mb-1 leading-[1.4]">
                          Email Address
                          <span className="text-red-500 inline">*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                        <p className="mt-1 text-[11px] text-gray-500">
                          Email associated with your corporation.
                        </p>
                      </div>

                      {/* Industry Sector Select */}
                      <div>
                        <label className="inline font-light text-md mb-1 leading-[1.4]">
                          Industry Sector
                          <span className="text-red-500 inline">*</span>
                        </label>
                        <TagsInput
                          data={[]}
                          value={selectedSectors}
                          onChange={handleMultiSelectChange}
                        />
                        <p className="mt-1 text-[11px] text-gray-500">
                         Type, press Enter to save and to add more sectors
                        </p>
                      </div>
                    </div>

                    {/* Desired Expertise Textarea */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Desired Expertise
                      </label>
                      <textarea
                        name="desired_expertise"
                        value={formData.desired_expertise}
                        onChange={handleChange}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-24 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                      <p className="mt-1 text-[11px] text-gray-500">
                        Optional field to specify specific expertise you are
                        seeking.
                      </p>
                    </div>

                    {/* Project Description Textarea */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Brief Project Description
                        <span className="text-red-500 inline">*</span>
                      </label>
                      <textarea
                        name="project_description"
                        value={formData.project_description}
                        onChange={handleChange}
                        required
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-24 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                      <p className="mt-1 text-[11px] text-gray-500">
                        Briefly describe your project or area of collaboration.
                      </p>
                    </div>

                    {/* Terms and Policy Checkbox */}


                    <div className="flex items-start">
                      <input
                        type="checkbox"
                        name="terms_and_policy"
                        checked={formData.terms_and_policy}
                        onChange={handleChange}
                        required
                        className="h-8 w-8 text-blue-600 border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                      />
                      <label className="ml-2 block text-gray-900">
                        I agree to the{" "}
                        <a
                          href="/terms_and_policy#terms"
                          className="text-blue-600 hover:text-blue-500 inline ml-1"
                        >
                          Terms of Service{" "}
                        </a>{" "}
                        and{" "}
                        <a
                          href="/terms_and_policy#privacy_policy"
                          className="text-blue-600 hover:text-blue-500 inline ml-1"
                        >
                          Privacy Policy
                        </a>
                        .
                      </label>
                    </div>

                    {/* Submit Button */}
                    <div>
                      <button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 rounded-lg"
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CorporateSignUpForm;
