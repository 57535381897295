import React from "react";
import styled from "styled-components";
import searchIcon from "./assets/searchbar.svg";

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
border-radius: 4px;
background: #F6F8FF;
padding: 8px 10px;
  max-width: 500px;
  width: 100%;
`;

const Input = styled.input`
  border: none;
  background: transparent;
  outline: none;
  flex: 1;
  font-size: 12px;
  color: #48504B;

  &::placeholder {
    color: #aaa;
  }
`;

const SearchButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const SearchBar = ({ onSearch }) => {
    const handleSearchClick = () => {
      if (onSearch) {
        onSearch();
      }
    };
  
    return (
      <SearchBarContainer>
        <Input placeholder="Search Startups, Research projects, Experts etc." />
        <SearchButton onClick={handleSearchClick}>
          <img src={searchIcon} alt="Search Icon" />
        </SearchButton>
      </SearchBarContainer>
    );
  };
  
  export default SearchBar;
