import React from "react";
import styled from "styled-components";
import PlusIcon from "./assets/plus.svg";
import UserIcon from "./assets/radix.svg";
import BalancedImage from "./assets/createProject.png";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useUserStore from "../stores/userStore";
import { useTranslation } from "react-i18next";

// Base styles for button/link component
const BaseStyles = styled.button`
  border-radius: 4px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-family: inherit;
  font-size: inherit;

  img {
    margin-right: 8px;
  }
`;

// Primary variant styles
const PrimaryButton = styled(BaseStyles)`
  background: #3566fd;
  color: #fff;
  max-width: 220px;

  &:hover {
    background-color: #007bff;
    color: #ffffff;
  }
`;

// Secondary variant styles
const SecondaryButton = styled(BaseStyles)`
  border: 1px solid #d0d5e5;
  background: transparent;

  &:hover {
    background-color: #007bff;
    color: #ffffff;
  }
`;

// Validate variant styles
const ValidateButton = styled(BaseStyles)`
  border: 1px solid #d0d5e5;
  background: #fff;
  backdrop-filter: blur(3px);
  width: 100%;

  &:hover {
    background-color: #0056b3;
    color: #ffffff;
  }
`;

// Container styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Content = styled.div`
  border-radius: 10px;
  background: #f6f8ff;
  padding: 20px;
  text-align: left;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 8px;
`;

const Subtitle = styled.p`
  font-size: 12px;
  color: #666666;
  margin-bottom: 16px;
  max-width: 80ch;
`;

// New user-specific styles
const NewUserContainer = styled.div`
  display: flex;
  padding: 20px 21px;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
`;

const ImageContainer = styled.img`
  width: 400px;
  height: 200px;
  border-radius: 10px;
  background: #f6f8ff;
`;

const NewUserContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

// StyledButton component
const StyledButton = ({
  variant = "primary",
  href,
  onClick,
  children,
  ...props
}) => {
  // Select the appropriate styled component based on variant
  const ButtonComponent = {
    primary: PrimaryButton,
    secondary: SecondaryButton,
    validate: ValidateButton,
  }[variant];

  // If href is provided, render as link
  if (href) {
    return (
      <ButtonComponent as="a" href={href} {...props}>
        {children}
      </ButtonComponent>
    );
  }

  // Otherwise render as button
  return (
    <ButtonComponent onClick={onClick} type="button" {...props}>
      {children}
    </ButtonComponent>
  );
};

const SidebarAction = ({ sidebar = false }) => {
  const [opened, { close, open }] = useDisclosure(false);
  const { user, startups } = useUserStore((state) => state.user);
  const { t } = useTranslation();

  // if (!sidebar && startups.length) return <></>;

  return (
    <>
      <Modal opened={opened} onClose={close} size="lg" withCloseButton={false}>
        <div className="p-4 overflow-y-auto">
          <p className="mt-1 text-gray-8000">
            {t("modal.profile_access_message")}
          </p>
        </div>
        <div className="flex justify-end items-center gap-x-2 py-3 px-4">
          <Button onClick={close} variant="default" className="px-12">
            {t("modal.cancel_button")}
          </Button>
          <Button
            href="/onboarding"
            component="a"
            variant="light"
            color="indigo"
            className="text-blue-700 px-12"
          >
            {t("modal.complete_profile_button")}
          </Button>
        </div>
      </Modal>

      {!sidebar ? (
        <NewUserContainer>
          <ImageContainer src={BalancedImage} alt="Perfectly Balanced Image" />
          <NewUserContent>
            <div>
              <Title>Kickstart Your Collaboration!</Title>
              <Subtitle>
                Create a new project with ease—whether it’s for a university
                assignment, a startup idea, or a collaborative team effort.
                Let's bring your ideas to life!
              </Subtitle>
            </div>
            <StyledButton
              variant="primary"
              href={user.profile_complete ? "/startup_entities/new" : undefined}
              onClick={!user.profile_complete ? open : undefined}
            >
              Create Project
              <img src={PlusIcon} alt="Create Project" />
            </StyledButton>
          </NewUserContent>
        </NewUserContainer>
      ) : (
        <Container>
          <Header>
            <StyledButton
              variant="primary"
              href={user.profile_complete ? "/startup_entities/new" : undefined}
              onClick={!user.profile_complete ? open : undefined}
            >
              Create New Project
              <img src={PlusIcon} alt="Create New Project" />
            </StyledButton>

            <StyledButton
              href="/dashboard/#/members?q=expert"
              variant="secondary"
            >
              Looking for an Expert
              <img src={UserIcon} alt="Looking for an Expert" />
            </StyledButton>
          </Header>

          <Content>
            <Title>Validate Your Idea</Title>
            <Subtitle>
              Test the strength of your idea and gain actionable feedback.
            </Subtitle>
            <StyledButton
              style={{ padding: "0" }}
              variant="validate"
              href={
                user.profile_complete || !user.validated_once
                  ? "/ai_validation_tool"
                  : undefined
              }
              onClick={
                !user.profile_complete && user.validated_once ? open : undefined
              }
            >
              Validate
            </StyledButton>
          </Content>
        </Container>
      )}
    </>
  );
};

export default SidebarAction;
