// utils/api.js

const csrfToken = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

const defaultHeaders = (formData) => {
  return formData
    ? { "X-CSRF-Token": csrfToken, Accept: "application/json" }
    : {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": csrfToken,
      };
};

const fetchBody = (body, formData) => {
  return formData ? body : JSON.stringify(body);
};

/**
 * fetchApi now supports a custom timeout (in milliseconds).
 * By default, timeout is set to 240000ms (4 minutes).
 */
const fetchApi = async (
  url,
  method = "GET",
  body = null,
  formData,
  customHeaders = {},
  timeout = 240000
) => {
  const headers = { ...defaultHeaders(formData), ...customHeaders };

  // Create an AbortController instance
  const controller = new AbortController();
  const timer = setTimeout(() => controller.abort(), timeout);

  const options = {
    method,
    headers,
    body: body ? fetchBody(body, formData) : null,
    signal: controller.signal,
  };

  try {
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    console.error("Fetch error:", error.message);
    throw new Error("Failed to fetch data");
  } finally {
    clearTimeout(timer);
  }
};

export default fetchApi;
