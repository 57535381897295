import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import sendIcon from "./assets/send-arrow.svg";
import FeatureButton from "./FeatureButton";
import ContainerButton from "./ContainerButton";
import { Button, Dialog, Drawer, Loader } from "@mantine/core";
import { IconBulb } from "@tabler/icons-react";
import SaveComponent from "../components/Startups/SaveComponent";
import GoogleDrivePickerAndUploader from "../components/Lib/FilesPickerAndUploader";
import useStartupStore from "../stores/startupStore";
import useUserStore from "../stores/userStore";
import MarkdownIt from "markdown-it";

// Styled components
const ChatDocumentUploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: #fff;
`;

const AttachedDocuments = styled.div`
  width: 40%;
  height: 100%;
`;

const SectionTitle = styled.p`
  margin-bottom: 8px;
  font-weight: var(--font-weight-h3);
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 24px;
`;

const ChatArea = styled.div`
  width: ${({ isexpanded }) => (isexpanded === "true" ? "40%" : "60%")};
  position: ${({ isexpanded }) =>
    isexpanded === "true" ? "fixed" : "relative"};
  bottom: ${({ isexpanded }) => (isexpanded === "true" ? "40%" : "0")};
  right: ${({ isexpanded }) => (isexpanded === "true" ? "-20%" : "0")};
  transform: ${({ isexpanded }) =>
    isexpanded === "true" ? "translateY(50%)" : "none"};
  background-color: #fff;
  border-radius: 8px;
  padding: ${({ isexpanded }) => (isexpanded === "true" ? "16px" : "unset")};
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: ${({ isexpanded }) => (isexpanded === "true" ? "1000" : "1")};
  box-shadow: ${({ isexpanded }) =>
    isexpanded === "true" ? "0 4px 20px rgba(0, 0, 0, 0.3)" : "none"};
`;

const ChatBox = styled.div`
  height: ${({ isexpanded }) => (isexpanded === "true" ? "70vh" : "250px")};
  border: 1px solid var(--color-navigation-icon-dark);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  display: inline;
  align-items: center;
  gap: 4px;
  color: var(--color-button-accent);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const ChatMessages = styled.div`
  padding: 5px;
  overflow-y: auto;
  flex-grow: 1;
`;

const ChatMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  flex-direction: ${(props) =>
    props.issent === "user" ? "row-reverse" : "row"};
`;

const ChatBubble = styled.div`
  background-color: ${(props) =>
    props.issent === "user" ? "#f1f1f1" : "var(--color-button-accent)"};
  padding: 8px;
  border-radius: 12px;
  max-width: 70%;
  font-size: 0.85rem;
  border: ${(props) => (props.issent === "user" ? "1px solid #ddd" : "none")};
  overflow-wrap: break-word;

  /* General text styling */
  p {
    margin: 0.5em 0;
    color: ${(props) => (props.issent === "user" ? "#333" : "white")};
  }

  /* Blockquote styling (used for AI highlights or quotes) */
  blockquote {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    background-color: ${(props) =>
      props.issent === "user" ? "#f9f9f9" : "rgba(255, 255, 255, 0.1)"};
    border-left: 4px solid
      ${(props) => (props.issent === "user" ? "#ccc" : "#6ba4ff")};
    color: ${(props) => (props.issent === "user" ? "#555" : "white")};
    font-style: italic;
  }

  /* Code block styling */
  pre {
    background: ${(props) =>
      props.issent === "user" ? "#f5f5f5" : "rgba(0, 0, 0, 0.8)"};
    color: ${(props) => (props.issent === "user" ? "#444" : "#e5e5e5")};
    padding: 10px;
    border-radius: 5px;
    font-size: 0.8rem;
    overflow-x: auto;
    font-family: "Courier New", Courier, monospace;
  }

  /* Inline code styling */
  code {
    background: ${(props) =>
      props.issent === "user" ? "#f5f5f5" : "rgba(0, 0, 0, 0.8)"};
    padding: 2px 4px;
    border-radius: 3px;
    font-family: "Courier New", Courier, monospace;
    color: ${(props) => (props.issent === "user" ? "#444" : "#e5e5e5")};
  }

  /* List styling */
  ul,
  ol {
    margin: 0.5em 0;
    padding-left: 20px;
    color: ${(props) => (props.issent === "user" ? "#333" : "white")};
  }

  li {
    margin-bottom: 5px;
    line-height: 1.5;
  }

  /* Table styling */
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 0.9rem;
    color: ${(props) => (props.issent === "user" ? "#333" : "white")};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
    text-decoration: underline;
  }

  th,
  td {
    padding: 8px 12px;
    border: 1px solid
      ${(props) =>
        props.issent === "user" ? "#ddd" : "rgba(255, 255, 255, 0.3)"};
    text-align: left;
  }

  th {
    background-color: ${(props) =>
      props.issent === "user" ? "#f1f1f1" : "rgba(255, 255, 255, 0.1)"};
    font-weight: bold;
  }

  /* Link styling */
  a {
    color: ${(props) => (props.issent === "user" ? "#007bff" : "#80d4ff")};
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${(props) => (props.issent === "user" ? "#0056b3" : "#66c3ff")};
    }
  }

  /* Image styling */
  img {
    max-width: 100%;
    border-radius: 5px;
    margin: 10px 0;
    display: block;
  }

  /* Horizontal rule styling */
  hr {
    border: 0;
    border-top: 1px solid
      ${(props) => (props.issent === "user" ? "#ddd" : "#555")};
    margin: 1em 0;
  }
`;

const ChatText = styled.p`
  color: #333;
  margin: 0; /* Remove any margin */
`;

const ChatInputArea = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #ddd;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: 1px solid var(--color-button-accent);
  border-radius: 8px;
  margin-right: 5px;
  font-size: 0.85rem;
  outline: none;
`;

const SendButton = styled.button`
  background-color: var(--color-button-accent);
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SendIcon = styled.img`
  width: 16px;
  &:hover {
    filter: brightness(0.8);
  }
`;

const ToggleModalButton = styled.button`
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ChatDocumentUpload = ({ close, setMessage, loading, setLoading }) => {
  const [chats, setChats] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [chatloading, setchatLoading] = useState(false);
  const [isPopup, setIsPopup] = useState(true);
  const mdParser = new MarkdownIt();
  const chatMessagesRef = useRef(null);
  const chatInputRef = useRef(null);

  const [isexpanded, setisexpanded] = useState(false);

  const toggleExpand = () => {
    setisexpanded(!isexpanded);
  };

  const { user } = useUserStore((state) => state.user);
  const {
    content,
    roadmapId,
    selectedProgram,
    setChats: updateStoreChats,
    submitPendingChanges,
  } = useStartupStore((state) => ({
    content: state.content,
    selectedProgram: state.selectedProgram,
    roadmapId: state.roadmapId,
    setChats: state.setChats,
    submitPendingChanges: state.submitPendingChanges,
  }));

  const pageId = selectedProgram.cid;
  const index = selectedProgram.sid;

  // Update chats when index (subcategory) changes
  useEffect(() => {
    if (content[pageId]?.[index]?.chats) {
      setChats(content[pageId][index].chats);
    } else {
      setChats([]);
    }
  }, [pageId, index, content]);

  // Streaming AI response function
  const fetchAIResponseStream = async (userId, projectId, userMessage) => {
    try {
      const response = await fetch("https://nowcollab.com/api/v1/chat/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          project_id: projectId,
          roadmap_id: roadmapId,
          message: userMessage,
          selected_step_number: pageId + 1 + "",
          temperature: 0.7,
          stream: true,
          subcategory_id: content[pageId]?.[index]?.id + "",
          category_id: content[pageId]?.[index]?.category_id + "",
        }),
      });

      if (!response.ok) {
        throw new Error(`API error: ${response.statusText}`);
      }

      return response;
    } catch (error) {
      console.error("Failed to fetch AI response", error);
      return null;
    }
  };

  // Updated handleSendMessage function for streaming
  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;

    const userChat = [{ role: "user", content: userMessage }];
    setChats((prevChats) => {
      const updatedChats = [...prevChats, userChat];
      updateStoreChats(updatedChats);
      return updatedChats;
    });
    setUserMessage("");
    setchatLoading(true);

    try {
      const response = await fetchAIResponseStream(
        `user_${user.id}`,
        "ecommerce-retail-usa-2025",
        userMessage
      );

      if (!response) {
        throw new Error("No response from server");
      }

      // Create a new AI chat entry with an empty message
      const aiChatIndex = chats.length + 1;
      setChats((prevChats) => [
        ...prevChats,
        [{ role: "assistant", content: "" }],
      ]);

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let fullResponse = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        fullResponse += chunk;

        setChats((prevChats) => {
          const updatedChats = [...prevChats];
          updatedChats[aiChatIndex][0].content = fullResponse;
          return updatedChats;
        });
      }

      setChats((prevChats) => {
        updateStoreChats(prevChats);
        return prevChats;
      });
    } catch (error) {
      console.error("Streaming error:", error);
      setChats((prevChats) => [
        ...prevChats,
        [
          {
            role: "assistant",
            content: "Sorry, something went wrong. Please try again.",
          },
        ],
      ]);
    } finally {
      setchatLoading(false);
      chatInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [chats]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !chatloading) {
      handleSendMessage();
    }
  };

  useEffect(() => {
    chatInputRef.current.focus();
  }, []);

  return (
    <ChatDocumentUploadContainer>
      <AttachedDocuments>
        <SectionTitle>Attached Documents</SectionTitle>
        <GoogleDrivePickerAndUploader />
        <ButtonContainer>
          <FeatureButton action={close} buttonText="Discard" />
          <>
            {loading ? (
              <Loader
                className="mx-auto"
                color="indigo"
                size="lg"
                variant="dots"
              />
            ) : (
              <ContainerButton
                action={async () => {
                  setLoading(true);
                  const result = await submitPendingChanges();
                  if (result.success) {
                    setMessage("Changes saved successfully");
                    setTimeout(() => {
                      setMessage("");
                    }, 1000);
                    // Optionally, close the modal: close();
                  } else {
                    setMessage(`Error saving changes: ${result.error}`);
                  }
                  setLoading(false);
                }}
                buttonText="Save & Complete"
              />
            )}
          </>
        </ButtonContainer>
      </AttachedDocuments>

      <ChatArea isexpanded={isexpanded + ""}>
        <SectionTitle>
          Smart AI Chat{" "}
          <ExpandButton onClick={toggleExpand}>
            {isexpanded ? "Minimize" : "Expand"}
          </ExpandButton>
        </SectionTitle>
        <ChatBox isexpanded={isexpanded + ""}>
          <ChatMessages ref={chatMessagesRef}>
            {chats.map((chat, index) => (
              <ChatMessage key={index} issent={chat[0].role}>
                <ChatBubble issent={chat[0].role}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mdParser.render(chat[0].content),
                    }}
                  />
                </ChatBubble>
              </ChatMessage>
            ))}
          </ChatMessages>

          <ChatInputArea>
            <ChatInput
              type="text"
              name="chat_area"
              placeholder="Type a message"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              ref={chatInputRef}
            />
            {chatloading ? (
              <Loader />
            ) : (
              <SendButton onClick={handleSendMessage} disabled={chatloading}>
                <SendIcon src={sendIcon} alt="Send" />
              </SendButton>
            )}
          </ChatInputArea>
        </ChatBox>
      </ChatArea>
    </ChatDocumentUploadContainer>
  );
};

export default ChatDocumentUpload;
