import React, { useState, useEffect } from "react";
import fetchApi from "../Lib/api";

const JoinWaitlistForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    linkedin: "",
    phone: "",
    cv: null, // For file upload
  });

  const [notification, setNotification] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      formDataToSend.append(`join_waitlist[${key}]`, value);
    }

    try {
      const response = await fetchApi("/join_waitlist", "POST", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setNotification(
          <div role="alert">
            <p className="section-text text-green-700">{data.message}</p>
            <p className="section-text">
              While you wait, feel free to connect with us on social media or
              check out some useful links.
            </p>
            <div className="flex justify-between mt-8">
              <ul className="social-list flex space-x-4">
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>
              </ul>
              <ul className="quicklink-list space-y-2 mt-4">
                <li>
                  <a href="#" className="quicklink">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#" className="quicklink">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        );
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          linkedin: "",
          phone: "",
          cv: null,
        });
        setIsSubmitted(true);
      } else {
        setNotification(
          <div role="alert" className="text-red-700">
            <p className="section-text">
              {data.message ||
                "There was an error submitting the form. Please try again."}
            </p>
          </div>
        );
      }
    } catch (error) {
      setNotification(
        <div role="alert" className="text-red-700">
          <p className="section-text">
            An error occurred. Please try again later.
          </p>
        </div>
      );
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsSubmitted(false);
    setNotification("");
  };

  return (
    <>
      <button onClick={openModal} className="btn btn-primary">
        Join the waitlist
      </button>

      {isModalOpen && (
        <div
          id="formModal"
          className="modal text-left shadow-sm fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-10"
          onClick={(e) => {
            if (e.target.id === "formModal") closeModal();
          }}
        >
          <div className="modal-content bg-white max-w-[370px] lg:max-w-[700px] rounded-lg relative mt-8 lg:mt-[10vh] ">
            <div className="modal-body">
              <form
                onSubmit={handleSubmit}
                className="space-y-6 p-4 lg:p-16 shadow-md rounded-lg"
              >
                {isSubmitted ? (
                  <div id="notification_area">{notification}</div>
                ) : (
                  <>
                    {notification && !isSubmitted && (
                      <div id="notification_area">{notification}</div>
                    )}
                    <div className="text-center mb-12">
                      <h1 className="block text-2xl font-bold text-gray-800">
                        Join the Waitlist
                      </h1>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      {/* First Name Input */}
                      <div>
                        <label className="inline font-light text-md mb-1 leading-[1.4]">
                          First Name
                          <span className="text-red-500 inline">*</span>
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>

                      {/* Last Name Input */}
                      <div>
                        <label className="inline font-light text-md mb-1 leading-[1.4]">
                          Last Name
                          <span className="text-red-500 inline">*</span>
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          required
                          className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        />
                      </div>
                    </div>

                    {/* Email Address Input */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Email Address
                        <span className="text-red-500 inline">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>

                    {/* LinkedIn Profile Input */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        LinkedIn Profile
                      </label>
                      <input
                        type="url"
                        name="linkedin"
                        value={formData.linkedin}
                        onChange={handleChange}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>

                    {/* Phone Number Input */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                      />
                    </div>

                    {/* CV Upload Input */}
                    <div>
                      <label className="inline font-light text-md mb-1 leading-[1.4]">
                        Upload CV
                      </label>
                      <input
                        type="file"
                        name="cv"
                        onChange={handleChange}
                        className="bg-white border border-gray-400 rounded-lg text-gray-900 font-normal h-16 leading-[1.5] px-3 py-2 focus:outline-none w-full"
                        accept=".pdf,.doc,.docx"
                      />
                    </div>

                    {/* Submit Button */}
                    <div>
                      <button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 rounded-lg"
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JoinWaitlistForm;