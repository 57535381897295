import React, { useState } from "react";
import styled from "styled-components";
import WarningIcon from "./assets/wAlert.svg"; // Replace with your warning icon
import UserImage from "./assets/userIcon.png"; // Replace with your user image
import CancelIcon from "./assets/wCancel.svg"; // Replace with your cancel icon
import useUserStore from "../stores/userStore";
import moment from "moment";

const WarningBanner = styled.div`
  border-radius: 6px;
  border: 1px solid #ffd2d2;
  display: flex;
  padding: 10px 19px;
  margin-bottom: 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #fdecec;
  color: #815454;
  font-size: 12px;

  a {
    color: #3566fd;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #0056b3;
    }
  }
`;

const UserProfile = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const Avatar = styled.img`
  border-radius: 10px;
  background: #ffd013;
  width: 100px;
  height: 100px;
  padding: 10px;
`;

const UserName = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 24px;
    color: #000;
    margin-block: 8px;
  }

  p {
    font-size: 12px;
    color: #494949;
    width: 80ch;
    margin-block: 2px;
  }
`;

const UserHeader = () => {
  const { user } = useUserStore((state) => state.user);
  const [showWarning, setShowWarning] = useState(!user.profile_complete);
  const currentDate = moment().format("dddd, MMMM Do YYYY");

  return (
    <div>
      {showWarning && (
        <WarningBanner>
          <img src={WarningIcon} alt="Warning" />
          Your profile is partially completed, you need to complete your profile
          first to get access to most of the features in the dashboard.{" "}
          <a href="/onboarding">Complete Your Profile Now</a>
          <img
            src={CancelIcon}
            alt="Close Warning"
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={() => setShowWarning(false)}
          />
        </WarningBanner>
      )}
      <UserProfile>
        <Avatar src={UserImage} alt="User Avatar" />
        <UserName>
          <h3>Welcome Back, {user.first_name}!</h3>
          <p>
            Keep collaborating, creating, and bringing your ideas to life. Let’s
            dive into your projects!
          </p>
          <p>{currentDate}</p>
        </UserName>
      </UserProfile>
    </div>
  );
};

export default UserHeader;
