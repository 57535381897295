import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ShareIcon from "./assets/share.svg";
import StarIcon from "./assets/star.svg";
import LocIcon from "./assets/loc.svg";
import noImage from "./assets/noImage.jpeg";

// Modified CardGrid to be responsive
const CardGrid = styled.div`
  display: grid;
  gap: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s ease;

  @media (min-width: 1204px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 768px) and (max-width: 1203px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.a`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-8px);
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 380px;
  background-size: cover;
  background-position: center;
  padding: 10px;
  border-radius: 6px;
`;

const Content = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  z-index: 1;
  border-radius: 6px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  color: #1e293b;
  font-size: 10px;

  border-radius: 50px;
  padding: 4px 10px;
`;

const Title = styled.h3`
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0;
  color: #111827;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Description = styled.p`
  font-size: 11px;
  color: #494949;
  margin-bottom: 8px;
  line-height: 16.5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-block: 10px;
  font-size: 11px;
  color: #494949;
`;

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FooterRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;

  ${Card}:hover & {
    opacity: 1;
    max-height: 40px; /* Adjust based on your button height */
    margin-top: 16px;
  }
`;

const ActionButton = styled.button`
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 4px;
  padding: 6px;

  &:hover {
    background: #2563eb;
  }
`;

const PrimaryButton = styled.button`
  border-radius: 4px;
  background: #3566fd;
  color: #fff;
  padding: 4px 10px;
  font-size: 11px;
  width: 100%;
  z-index: 100;
  &:hover {
    background-color: #fff;
    color: #007bff;
  }
`;

const SecondaryButton = styled.button`
  border-radius: 4px;
  border: 1px solid #d0d5e5;
  background: #fff;
  padding: 4px 10px;
  font-size: 11px;
  color: #3566fd;
  width: 100%;
  &:hover {
    background-color: #007bff;
    color: #ffffff;
  }
`;

// Container needs to be responsive
const Container = styled.div`
  position: relative;
  display: flex;
  gap: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

// Wrapper for cards section
const CardsSection = styled.div`
  flex: 1;
  overflow: hidden;
`;

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${(props) => props.slideIndex * 100}%);
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  ${(props) =>
    props.direction === "left"
      ? `
    left: -20px;
  `
      : `
    right: -20px;
  `}
`;

// Adjust pagination wrapper for mobile
const PaginationWrapper = styled.div`
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: ${(props) => (props.expanded ? 1 : 0)};
  transition: opacity 0.3s ease;

  @media (max-width: 767px) {
    position: static;
    transform: none;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
`;

const PaginationButton = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #3566fd;
  background: ${(props) => (props.active ? "#3566fd" : "white")};
  color: ${(props) => (props.active ? "white" : "#3566fd")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${(props) => (props.active ? "#2563eb" : "#f0f0f0")};
  }
`;

const ViewMoreButton = styled.button`
  margin: 20px auto;
  display: block;
  padding: 4px 12px;
  background: #3566fd;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background: #2563eb;
  }
`;

const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  transition: all 0.3s ease;
`;

const StartupCards = ({ allCards = [] }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [slideIndex, setSlideIndex] = useState(0);
  const [cardsPerRow, setCardsPerRow] = useState(4);

  console.log(allCards);

  useEffect(() => {
    const updateCardsPerRow = () => {
      if (window.innerWidth >= 1204) {
        setCardsPerRow(4);
      } else if (window.innerWidth >= 768) {
        setCardsPerRow(3);
      } else {
        setCardsPerRow(1);
      }
    };

    updateCardsPerRow();
    window.addEventListener("resize", updateCardsPerRow);
    return () => window.removeEventListener("resize", updateCardsPerRow);
  }, []);

  // Duplicate your cards array for demonstration
  // const allCards = [...Array(16)].map((_, index) => ({
  //   ...cards[index % cards.length],
  //   title: cards[index % cards.length] .title+ " " + index,
  //   id: index
  // }));

  const cardsPerPage = expanded ? cardsPerRow * 2 : cardsPerRow;
  const totalPages = Math.ceil(allCards.length / cardsPerPage);

  const handlePrevSlide = () => {
    if (!expanded && slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
    }
  };

  const handleNextSlide = () => {
    if (
      !expanded &&
      slideIndex < Math.ceil(allCards.length / cardsPerRow) - 1
    ) {
      setSlideIndex(slideIndex + 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleViewMoreClick = () => {
    setExpanded(!expanded);
    setSlideIndex(0);
    setCurrentPage(1);
  };

  const visibleCards = expanded
    ? allCards.slice(
        (currentPage - 1) * cardsPerPage,
        currentPage * cardsPerPage
      )
    : allCards.slice(slideIndex * cardsPerRow, (slideIndex + 1) * cardsPerRow);

  return (
    <div>
      <Container>
        <CardsSection>
          {!expanded && (
            <>
              <NavigationButton
                direction="left"
                onClick={handlePrevSlide}
                disabled={slideIndex === 0}
              >
                ←
              </NavigationButton>
              <NavigationButton
                direction="right"
                onClick={handleNextSlide}
                disabled={
                  slideIndex >= Math.ceil(allCards.length / cardsPerRow) - 1
                }
              >
                →
              </NavigationButton>
            </>
          )}

          <GridContainer expanded={expanded}>
            <CardGrid>
              {visibleCards.map((card, index) => (
             <Card
             onClick={(e) => {
               // If we didn't click a button, navigate to the main card link
               if (!e.target.closest('button')) {
                 window.location.href = `${
                   card.entity === "Project" ? "/project_entities" : "/startup_entities"
                 }/${card.slug}`;
               }
             }}
             key={card.id}
           >
             <ImageWrapper
               style={{
                 backgroundImage: `url(${card.image_url || noImage})`,
               }}
             >
               <div
                 style={{
                   display: "flex",
                   justifyContent: "space-between",
                   alignItems: "center",
                 }}
               >
                 <TagWrapper>
                   {[
                     card.entity,
                     card.industry ? card.industry : card.project_type,
                   ].map((tag, i) => (
                     <Tag key={i}>{tag}</Tag>
                   ))}
                 </TagWrapper>
                 <ActionButton>
                   <img src={ShareIcon} alt="Share" />
                 </ActionButton>
               </div>
               <Content>
                 <Title>{card.name}</Title>
                 <Description>{card.description}</Description>
                 <Actions>
                   {card.is_owner ? (
                     <SecondaryButton
                       onClick={(e) => {
                         e.stopPropagation();
                         window.location.href = `${
                           card.entity === "Project"
                             ? "/project_entities"
                             : "/startup_entities"
                         }/${card.slug}?invite_open=true`;
                       }}
                     >
                       Invite Collaborators
                     </SecondaryButton>
                   ) : (
                     <>
                       <PrimaryButton
                         onClick={(e) => {
                           e.stopPropagation();
                           window.location.href = `${
                             card.entity === "Project"
                               ? "/project_entities"
                               : "/startup_entities"
                           }/${card.slug}?manage_request=true`;
                         }}
                       >
                         Join {card.entity}
                       </PrimaryButton>
                       <SecondaryButton
                         onClick={(e) => {
                           e.stopPropagation();
                           // Add your follow handler here
                         }}
                       >
                         Follow
                       </SecondaryButton>
                     </>
                   )}
                 </Actions>
               </Content>
             </ImageWrapper>
             <Footer>
               <FooterLeft>
                 <span>{card.openings_length + " Open positions"}</span>
                 <Location>
                   <img
                     src={LocIcon}
                     alt="Location"
                     style={{ width: "16px" }}
                   />
                   <span>{card.city + " " + card.country}</span>
                 </Location>
               </FooterLeft>
               <FooterRight>
                 <img
                   src={StarIcon}
                   alt="Rating"
                   style={{ width: "16px" }}
                 />
                 <span>{4}</span>
               </FooterRight>
             </Footer>
           </Card>
              ))}
            </CardGrid>
          </GridContainer>
        </CardsSection>

        <PaginationWrapper expanded={expanded}>
          {[...Array(totalPages)].map((_, index) => (
            <PaginationButton
              key={index + 1}
              active={currentPage === index + 1}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </PaginationButton>
          ))}
        </PaginationWrapper>
      </Container>

      <ViewMoreButton onClick={handleViewMoreClick}>
        {expanded ? "Show Less" : "View More"}
      </ViewMoreButton>
    </div>
  );
};

export default StartupCards;

// const cards = [
//     {
//       image: s1,
//       tags: ["Startup", "Founding Team"],
//       category: "Art and Design",
//       title: "Sed et lacus justo. Pellentesque quis magna.",
//       description:
//         "Donec sed sapien eu mi elementum vestibulum ut at quam. Integer at...",
//       positions: "4 Open positions",
//       location: "Rhône-Alpes, France",
//       rating: 4.0,
//       status: "Join StartUp",
//     },
//     {
//       image: s2,
//       tags: ["Project", "Contributor"],
//       category: "Art and Design",
//       title: "Aenean gravida urna eget libero tincidunt vulputate.",
//       description:
//         "Donec sed sapien eu mi elementum vestibulum ut at quam. Integer at...",
//       positions: "No seats available",
//       location: "Notre Dame Du Laus, Canada",
//       rating: 4.0,
//       status: "View Project",
//     },
//     {
//       image: s1,
//       tags: ["Idea", "Intern"],
//       category: "Industrial Design",
//       title: "Pellentesque habitant morbi.",
//       description:
//         "Donec sed sapien eu mi elementum vestibulum ut at quam. Integer at...",
//       positions: "6 Open positions",
//       location: "Vancouver, Canada",
//       rating: 4.0,
//       status: "Apply Now",
//     },
//     {
//       image: s2,
//       tags: ["Prototype", "Renewable Energy"],
//       category: "Construction",
//       title: "Etiam pretium tellus in sem.",
//       description:
//         "Donec sed sapien eu mi elementum vestibulum ut at quam. Integer at...",
//       positions: "2 Open positions",
//       location: "Tirana, Albania",
//       rating: 4.0,
//       status: "View Prototype",
//     },
//   ];
