import React from "react";
import EditorContentDisplay from "../../stories/EditorContentDisplay";

const DiffDisplay = ({ diffResult }) => {
  const renderJsonDiff = (oldData, newData) => {
    return (
      <div>
        <div className="bg-red-50 p-4 rounded">
          <div className="font-bold text-red-700 mb-2">Before</div>
          <pre className="whitespace-pre-wrap text-sm">
            <EditorContentDisplay content={{ blocks: [oldData] }} />
          </pre>
        </div>
        <div className="bg-green-50 p-4 rounded">
          <div className="font-bold text-green-700 mb-2">After</div>
          <pre className="whitespace-pre-wrap text-sm">
            <EditorContentDisplay content={{ blocks: [newData] }} />
          </pre>
        </div>
      </div>
    );
  };

  const renderChange = (change) => {
    const getChangeType = () => {
      if (change.oldPosition !== undefined) return "reordered";
      if (
        diffResult.added.some((added) => added.block?.id === change.block?.id)
      )
        return "added";
      if (
        diffResult.removed.some(
          (removed) => removed.block?.id === change.block?.id
        )
      )
        return "removed";
      if (
        diffResult.modified.some(
          (modified) =>
            modified.old?.id === change.old?.id ||
            modified.blockId === change.blockId
        )
      )
        return "modified";
      return "unchanged";
    };

    const getChangeClass = (type) => {
      switch (type) {
        case "added":
          return "p-3 bg-green-100";
        case "removed":
          return "p-3 bg-red-100";
        case "modified":
        case "reordered":
          return "";
        default:
          return "";
      }
    };

    const renderContent = (change, type) => {
      if (type === "modified") {
        return renderJsonDiff(change.old, change.new);
      } else if (type === "reordered") {
        if (change.changes) {
          return renderJsonDiff(change.changes.old, change.changes.new);
        }
        return (
          <pre className="whitespace-pre-wrap">
            <EditorContentDisplay content={{ blocks: [change.block] }} />
          </pre>
        );
      }
      return (
        <pre className="whitespace-pre-wrap">
          <EditorContentDisplay content={{ blocks: [change.block] }} />
        </pre>
      );
    };

    const type = getChangeType();
    const id =
      change.block?.id || change.blockId || change.old?.id || "unknown";

    return (
      <div
        key={id}
        className={`${getChangeClass(type)}`}
      >
        {renderContent(change, type)}
      </div>
    );
  };

  return (
    <div className="mt-2">
      {diffResult && (
        <div>
          {diffResult.orderedChanges.length > 0 ? (
            diffResult.orderedChanges.map((change) => renderChange(change))
          ) : (
            <div className="text-gray-600">No changes detected</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DiffDisplay;
