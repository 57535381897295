import React, { useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import EditorJsDiff from './EditorJsDiff';
import DiffDisplay from './DiffDisplay';

const DiffTester = () => {
  const editor1Ref = useRef(null);
  const editor2Ref = useRef(null);
  const [diffResult, setDiffResult] = useState(null);

  const initialData = {
    blocks: [
      {
        id: "1",
        type: "header",
        data: {
          text: "Test Document",
          level: 1
        }
      },
      {
        id: "2",
        type: "paragraph",
        data: {
          text: "This is a test paragraph. Edit this content to see the diff."
        }
      },
      {
        id: "3",
        type: "list",
        data: {
          style: "unordered",
          items: [
            "Item 1",
            "Item 2",
            "Item 3"
          ]
        }
      }
    ]
  };

  useEffect(() => {
    editor1Ref.current = new EditorJS({
      holder: 'editor1',
      tools: {
        header: Header,
        list: List
      },
      data: initialData,
      onChange: () => {
        handleCompare();
      }
    });

    editor2Ref.current = new EditorJS({
      holder: 'editor2',
      tools: {
        header: Header,
        list: List
      },
      data: initialData,
      onChange: () => {
        handleCompare();
      }
    });

    return () => {
      if (editor1Ref.current) {
        editor1Ref.current.destroy();
      }
      if (editor2Ref.current) {
        editor2Ref.current.destroy();
      }
    };
  }, []);

  const handleCompare = async () => {
    try {
      const content1 = await editor1Ref.current.save();
      const content2 = await editor2Ref.current.save();
      
      const differ = new EditorJsDiff();
      const result = differ.compareContent(content1, content2);
      setDiffResult(result);
    } catch (error) {
      console.error('Error comparing content:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-2 gap-8">
        <div>
          <h2 className="text-xl font-bold mb-4">Editor 1</h2>
          <div id="editor1" className="border p-4 rounded" />
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4">Editor 2</h2>
          <div id="editor2" className="border p-4 rounded" />
        </div>
      </div>

      <DiffDisplay diffResult={diffResult} />
    </div>
  );
};

export default DiffTester;