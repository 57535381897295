import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useUserStore from "../stores/userStore";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  ActionIcon,
  CopyButton,
  Tooltip,
  Button,
  Modal,
  Stack,
  TextInput,
  Checkbox,
  Title,
  Group,
  Text,
} from "@mantine/core";
import {  IconUsersPlus } from "@tabler/icons-react";
import fetchApi from "../components/Lib/api";
import Notification from "../components/Lib/Notification";

// Styled Components
const StartupButtonContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const DropdownIcon = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const DropdownLink = styled.a`
  display: flex;
  align-items: center;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: #f2f2f2;
  }
`;

function InviteUser({ activeDomain }) {
  const [notification, setNotification] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      addToSubdomain: false,
    },
    // Optionally, you can add validation rules here
    validate: {
      firstName: (value) => (value ? null : "First name is required"),
      lastName: (value) => (value ? null : "Last name is required"),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

 
  const handleSubmit = async (values) => {
    try {
      const response = await fetchApi(
        "/users/sessions/invitations/create_and_invite",
        "POST",
        {
          user: {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            add_to_subdomain: values.addToSubdomain,
          },
          active_domain_name: activeDomain ? activeDomain.name : "",
        }
      );

      if (response.ok) {
        const msg = (await response.json()).message;
        setNotification({ type: "success", content: msg });
        console.log("User invited successfully:", res);
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error("Error inviting user:", errorData.error);
      }
    } catch (error) {
      console.error("Error during user invitation:", error);
    }
    form.reset();
  };

  return (
    <>
     {opened &&  <Modal
        opened={opened}
        onClose={close}
        size="lg"
        radius="md"
        transitionProps={{ transition: "fade", duration: 200 }}
        withCloseButton={false}
      >
        <Stack spacing={18} className="p-5">
        {notification && <Notification {...notification} />}
          <Title order={5}>Invite User</Title>
          <Text size="md">
            Enter the details for the user you wish to invite.
          </Text>

           {/* If activeDomain is valid, show the copy invite link option */}
           {activeDomain && activeDomain.signup_url && (
            <>
              <Text size="md" color="dimmed">
                Alternatively, you can copy the invite link below if you prefer
                not to fill out the form:
              </Text>
              <CopyButton value={activeDomain.signup_url} timeout={2000}>
                {({ copied, copy }) => (
                  <Button variant="outline" onClick={copy}>
                    {copied ? "Copied" : "Copy Invite Link"}
                  </Button>
                )}
              </CopyButton>
            </>
          )}

          <Group grow>
            <TextInput
              size="md"
              label="First Name"
              {...form.getInputProps("firstName")}
            />
            <TextInput
              size="md"
              label="Last Name"
              {...form.getInputProps("lastName")}
            />
          </Group>
          <TextInput
            size="md"
            label="Email"
            type="email"
            {...form.getInputProps("email")}
          />
         {/* Show checkbox only if user is on a subdomain (activeDomain valid) */}
          {activeDomain && activeDomain.signup_url && (
            <Checkbox
              label={`Should the user also be addeed to ${activeDomain.name}`}
              {...form.getInputProps("addToSubdomain", { type: "checkbox" })}
            />
          )}
          <Button
            onClick={() => handleSubmit(form.values)}
            variant="default"
            fullWidth
            className="w-full px-12"
            disabled={
              !form.values.firstName ||
              !form.values.lastName ||
              !form.values.email
            }
          >
            Submit Invitation
          </Button>
        </Stack>
      </Modal>}

      <Tooltip
        label="Invite a User"
        withArrow
        position="right"
        color="teal"
        p={12}
      >
        <ActionIcon
          color="teal"
          variant="subtle"
          onClick={open}
        >
           <IconUsersPlus size={18} />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

const StartupButton = () => {
  const {
    user: {
      startups,
      domains,
      user: { full_name, avatar },
    },
  } = useUserStore();

  const [startupDropdownOpen, setStartupDropdownOpen] = useState(false);
  const [domainDropdownOpen, setDomainDropdownOpen] = useState(false);
  const [activeStartup, setActiveStartup] = useState(null);
  const [activeDomain, setActiveDomain] = useState(null);

  // Insert a main site domain entry
  const domainsWithMain = [{ name: "Main Site", subdomain: "" }, ...domains];

  useEffect(() => {
    const currentHostname = window.location.hostname;
    const currentSubdomain =
      currentHostname.split(".")[0] === "example"
        ? ""
        : currentHostname.split(".")[0];
    const matchedDomain = domainsWithMain.find(
      (domain) => domain.domain === currentSubdomain
    );

    if (matchedDomain) setActiveDomain(matchedDomain);
    else setActiveDomain(domainsWithMain[0]);

    const currentSlug = window.location.pathname.split("/").pop();
    const matchedStartup = startups.find(
      (startup) => startup.slug === currentSlug
    );

    if (matchedStartup) setActiveStartup(matchedStartup);
  }, []);

  const toggleStartupDropdown = () => {
    setStartupDropdownOpen(!startupDropdownOpen);
    setDomainDropdownOpen(false);
  };

  const toggleDomainDropdown = () => {
    setDomainDropdownOpen(!domainDropdownOpen);
    setStartupDropdownOpen(false);
  };

  const handleStartupSwitch = (startup) => {
    setActiveStartup(startup);
    setStartupDropdownOpen(false);
  };

  const handleDomainSwitch = (domain) => {
    if (domain === activeDomain) {
      setDomainDropdownOpen(false);
      return;
    }

    setActiveDomain(domain);
    setDomainDropdownOpen(false);

    const currentUrl = new URL(window.location.href);
    const domainParts = currentUrl.hostname.split(".");

    // Remove port if it exists
    const port = currentUrl.port ? `:${currentUrl.port}` : "";

    // Extract the base domain (last two parts of the hostname)
    // For example, from "sub.example.com", get "example.com"
    const baseDomain =
      domainParts.length > 2
        ? domainParts.slice(-2).join(".")
        : currentUrl.hostname;

    // Construct the new URL
    let newHostname;
    if (domain.domain) {
      newHostname = `${domain.domain}.${baseDomain}`;
    } else {
      newHostname = baseDomain;
    }

    // Reconstruct the full URL with the new hostname and port
    const newUrl = `${currentUrl.protocol}//${newHostname}${port}${currentUrl.pathname}${currentUrl.search}${currentUrl.hash}`;

    window.location.href = newUrl;
  };

  // if (!startups.length && domainsWithMain.length === 1) return null;

  return (
    <StartupButtonContainer>
      {/* Domain Selector */}
      <div className="relative inline-block w-64 w-full">
        <div className="flex items-center gap-2">
          <button
            onClick={toggleDomainDropdown}
            className="flex items-center justify-between w-full p-3 bg-white rounded-lg shadow-sm hover:bg-gray-50"
          >
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-green-500 mr-3"></div>
              {/* Display active domain or main site */}
              <span className="text-gray-900">{activeDomain?.name}</span>
            </div>
            <DropdownIcon>
              <svg
                className={`w-2 h-2 transform ${
                  domainDropdownOpen ? "rotate-180" : ""
                }`}
                viewBox="0 0 8 5"
                fill="none"
              >
                <path d="M4 4.25L0.25 0.5H7.75L4 4.25Z" fill="#514D45" />
              </svg>
            </DropdownIcon>
          </button>
          {/* Copy invite link button */}
          <InviteUser activeDomain={activeDomain} />
        </div>

        {domainDropdownOpen && (
          <DropdownMenu>
            {domainsWithMain.map((domain, index) => (
              <DropdownLink
                key={index}
                onClick={() => handleDomainSwitch(domain)}
              >
                <div className="w-3 h-3 rounded-full bg-green-500 mr-3"></div>
                <span className="text-gray-900">{domain.name}</span>
              </DropdownLink>
            ))}
          </DropdownMenu>
        )}
      </div>
    </StartupButtonContainer>
  );
};

export default StartupButton;
