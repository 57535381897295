import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  Group,
  Indicator,
  Menu,
  Modal,
  Text,
  rem,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import fetchApi from "../Lib/api";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import Notification from "../Lib/Notification";
import FeatureButton from "../../stories/FeatureButton";
import useStartupStore from "../../stores/startupStore";
import { useTranslation } from "react-i18next";

export default function ManageRequestToJoinTeam() {
  const [opened, { open, close }] = useDisclosure(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("manage_request")) fetchData();
  }, [open, close]);

  const {
    isOwner,
    isMember,
    slug,
    entity,
    role,
    setMembers,
    removeMembers,
    pendingJoinRequest,
  } = useStartupStore((state) => ({
    slug: state.slug,
    isOwner: state.isOwner,
    isMember: state.isMember,
    role: state.role,
    setMembers: state.setMembers,
    removeMembers: state.removeMembers,
    entity: state.entity,
    pendingJoinRequest: state.pendingJoinRequest,
  }));

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/join_requests/pending_requests_for_startup`,
        "GET"
      );

      if (response.ok) {
        const res = await response.json();
        // setNotification({ type: "success", content: msg });
        console.log(res);
        setUsers(res);
        open();
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequest = async (accepted, index) => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/join_requests/${
          users.pending_requests[index].join_request_id
        }/${accepted ? "accept" : "reject"}_request`,
        "PATCH"
      );

      if (response.ok) {
        const res = await response.json();
        setNotification({ type: "success", content: res.message });

        if (accepted) {
          setMembers(res.user);
          setUsers((u) => ({
            not_pending_requests: [
              ...u.not_pending_requests,
              u.pending_requests[index],
            ],
            pending_requests: u.pending_requests.filter((_, i) => i !== index),
          }));
        } else
          setUsers((u) => ({
            ...u,
            pending_requests: u.pending_requests.filter((_, i) => i !== index),
          }));
      } else {
        const msg = (await response.json()).error;
        setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
  };

  const handleRole = async (membershipId, newRoleIndex) => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/update_role`,
        "PUT", // Use PUT method to update the role
        { membership_id: membershipId, new_role: newRoleIndex }
      );

      if (response.ok) {
        const res = await response.json();
        setNotification({ type: "success", content: res.message });
      } else {
        const { error } = await response.json();
        setNotification({ type: "failure", content: error });
        console.error(error);
      }
    } catch (error) {
      console.error("Error during role update:", error);
    }
  };

  const removeMember = async (user_id, index) => {
    try {
      const response = await fetchApi(
        `/startup_entities/${slug}/remove_member`,
        "DELETE", // Use PUT method to update the role
        { user_id }
      );

      if (response.ok) {
        const res = await response.json();
        setNotification({ type: "success", content: res.message });
        console.log(res);
        removeMembers(user_id || res.user_id);
        if (index !== undefined) {
          setUsers((p) => {
            const newRequests = [
              ...p.not_pending_requests.slice(0, index),
              ...p.not_pending_requests.slice(index + 1),
            ];
            return { ...p, not_pending_requests: newRequests };
          });
        }
      } else {
        const { error } = await response.json();
        setNotification({ type: "failure", content: error });
        console.error(error);
      }
    } catch (error) {
      console.error("Error during role update:", error);
    }
  };

  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          size="xl"
          onClose={() => {
            setNotification(null);
            close();
          }}
          withCloseButton={false}
        >
          {notification && <Notification {...notification} />}
          <Card radius="md">
            <Card.Section withBorder inheritPadding py="xs">
              <Text fw={500}>{t("startups_page.manage_team.pending")}</Text>
            </Card.Section>
            <div className="flow-root">
              <ul
                role="list"
                className="divide-y divide-gray-200 dark:divide-gray-700 list-none"
              >
                {users.pending_requests.map(({ user }, i) => (
                  <li className="py-3 sm:py-4" key={user.email}>
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Avatar
                          src={
                            user.avatar_url ||
                            "https://placehold.co/600x400?text=No Image"
                          }
                          mx="auto"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>

                      <div className="flex-1 min-w-0">
                        <a href={`/profile/${user.id}`}>
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {`${user.first_name} ${user.last_name}`}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            {user.email}
                          </p>
                        </a>
                      </div>

                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        <Group gap={0} justify="flex-end">
                          <ActionIcon
                            variant="default"
                            size="xl"
                            onClick={() => handleRequest(true, i)}
                          >
                            <IconCheck
                              style={{ width: rem(22), height: rem(22) }}
                              stroke={1.5}
                            />
                          </ActionIcon>
                          <ActionIcon
                            variant="default"
                            size="xl"
                            onClick={() => handleRequest(false, i)}
                          >
                            <IconX
                              style={{ width: rem(22), height: rem(22) }}
                              color="red"
                              stroke={1.5}
                            />
                          </ActionIcon>
                        </Group>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <Card.Section withBorder inheritPadding pt={25} pb={10}>
              <Text fw={500}>{t("startups_page.manage_team.all")}</Text>
            </Card.Section>
            <div className="flow-root">
              <ul
                role="list"
                className="divide-y divide-gray-200 dark:divide-gray-700 list-none"
              >
                {users.not_pending_requests.map(({ user }, i) => (
                  <li className="py-3 sm:py-4" key={user.email}>
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Avatar
                          src={
                            user.avatar_url ||
                            "https://placehold.co/600x400?text=No Image"
                          }
                          mx="auto"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>

                      <div className="flex-1 min-w-0">
                        <a href={`/profile/user/${user.id}`}>
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {`${user.first_name} ${user.last_name}`}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            {user.email}
                          </p>
                        </a>
                      </div>

                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        <Group gap={0} justify="flex-end">
                          <CustomDropdown
                            id={user.membership_id}
                            handleRole={handleRole}
                            role={user.role}
                          />
                          <ActionIcon
                            variant="default"
                            size="xl"
                            onClick={() => removeMember(user.id, i)}
                          >
                            <IconTrash
                              style={{ width: rem(22), height: rem(22) }}
                              color="red"
                              stroke={1.5}
                            />
                          </ActionIcon>
                        </Group>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Card>
        </Modal>
      )}
      {!(isOwner || role < 1) ? (
        <>
          {isMember ? (
            <LeaveStartup removeMember={removeMember} entity={entity} />
          ) : (
            <></>
          )}
        </>
      ) : (
        <Indicator
          withBorder
          disabled={!pendingJoinRequest}
          processing
          color="green"
          position="top-start"
          size={15}
        >
          <FeatureButton
            action={fetchData}
            buttonText={t("startups_page.members.manage_team")}
            hidePlus={true}
          />
        </Indicator>
      )}
    </>
  );
}

const LeaveStartup = ({ removeMember, entity }) => {
  const [opened, { close, open }] = useDisclosure(false);
  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          size="lg"
          withCloseButton={false}
        >
          <div class="p-4 overflow-y-auto">
            <p class="mt-1 text-gray-8000">
              You are about to leave this {entity.toLowerCase()}. You will loose
              all access a member have.
            </p>
          </div>
          <div class="flex justify-end items-center gap-x-2 py-3 px-4">
            <Button onClick={close} variant="default" className=" px-12">
              Cancel
            </Button>
            <Button
              onClick={() => removeMember(null, undefined)}
              variant="default"
              className="text-red-700 px-12"
            >
              Proceed anyway
            </Button>
          </div>
        </Modal>
      )}
      <FeatureButton
        action={open}
        buttonText={`Leave ${entity}`}
        hidePlus={true}
      />
    </>
  );
};

const CustomDropdown = ({ id, handleRole, role }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(role); // Default to the index of the first role

  const roles = [
    "Founding Team",
    "Contributor",
    "Intern/Volunteer",
    "Consultant",
    "Investor",
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleRoleChange = (index) => {
    setSelectedRoleIndex(index);
    setIsOpen(false);
    handleRole(id, index);
  };

  return (
    <div className="relative inline-block text-left">
      <Menu
        opened={isOpen}
        onChange={toggleDropdown}
        shadow="md"
        width={200}
        withinPortal={true}
      >
        <Menu.Target>
          <Button variant="default">
            {roles[selectedRoleIndex] || role}
            <svg
              className={`w-2.5 h-2.5 ms-3 ${isOpen ? "rotate-180" : ""}`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <ul className="py-3">
            {roles.map((role, index) => (
              <li
                key={index}
                className="relative flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <input
                  id={`role-${index}`}
                  type="radio"
                  value={index}
                  name="team-role"
                  checked={selectedRoleIndex === index}
                  onChange={() => handleRoleChange(index)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  htmlFor={`role-${index}`}
                  className="w-full ms-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                >
                  {role}
                </label>
              </li>
            ))}
          </ul>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};
