import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DateTimePicker } from "@mantine/dates";
import React, { useState } from "react";
import fetchApi from "../Lib/api";
import { useTranslation } from "react-i18next";
import FeatureButton from "../../stories/FeatureButton";

export default function Meeting({ canSchedule, slug }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { t } = useTranslation();

  const handleSchedule = async () => {
    try {
      const response = await fetchApi("/meetings", "POST", {
        slug: slug,
        meeting: {
          title,
          description,
          start_time: startTime,
          end_time: endTime,
        },
      });

      if (response.ok) {
        const res = await response.json();
        // setNotification({ type: "success", content: msg });
        console.log(res);
        // window.location.href = `/startup_entities/${res.id}`;
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during password change:", error);
    }
    close();
  };

  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          size="lg"
          radius="md"
          transitionProps={{ transition: "fade", duration: 200 }}
          withCloseButton={false}
        >
          <Stack spacing={18} className="p-5">
            <Title order={5}>{t("startups_page.meeting.header")}</Title>
            <Text size="md">{t("startups_page.meeting.desc")}</Text>
            <TextInput
              size="md"
              label={t("startups_page.meeting.title")}
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
            <Group grow>
              <DateTimePicker
                label={t("startups_page.meeting.start")}
                valueFormat="ddd MMM YYYY hh:mm A"
                size="md"
                clearable
                minDate={new Date()}
                onChange={(value) => setStartTime(value)}
              />
              <DateTimePicker
                label={t("startups_page.meeting.end")}
                valueFormat="ddd MMM YYYY hh:mm A"
                size="md"
                clearable
                minDate={startTime || new Date()}
                onChange={(value) => setEndTime(value)}
              />
            </Group>
            <Textarea
              size="md"
              radius="md"
              label={t("startups_page.meeting.agenda")}
              placeholder={t("startups_page.meeting.agenda_p")}
              onChange={(e) => setDescription(e.currentTarget.value)}
            />
            <Button
              onClick={handleSchedule}
              variant="default"
              fullWidth
              className="w-full px-12"
              disabled={!(title && description && startTime && endTime)}
            >
              {canSchedule
                ? t("startups_page.meeting.buttonText_m")
                : t("startups_page.members.request_meeting")}
            </Button>
          </Stack>
        </Modal>
      )}
      <FeatureButton
        action={open}
        buttonText={
          canSchedule
            ? t("startups_page.members.schedule")
            : t("startups_page.members.request_meeting")
        }
      />
    </>
  );
}
