import React from "react";
import styled from "styled-components";

// Import logos
import vnips from "./assets/vnips.png";
import mindsbourg from "./assets/mindsbourg.png";
import nc from "./assets/logo.png";

// Styled Components for Logo
const LogoContainer = styled.a`
  display: flex;
  gap: 5px;
  align-items: center;
  text-decoration: none;
`;

const LogoImage = styled.img`
  height: ${({ height }) => height || "auto"};
  margin-bottom: 5px;
`;

const BetaTag = styled.div`
  background: #3d3b4b;
  padding: 2px 4px;
  border-radius: 5px;
  margin-top: 8px;
  color: var(--color-container-card-default);
  font-family: "Montserrat", sans-serif;
  font-weight: var(--font-weight-body-1);
  font-size: var(--font-size-body-4);
  height: fit-content;
`;

const Logo = () => {
  const hostname = window.location.hostname;
  
  // Improved subdomain detection
  const getSubdomain = (hostname) => {
    const parts = hostname.split('.');
    // Check if it's a valid subdomain (not www and has more than 2 parts)
    if (parts.length > 2 && parts[0] !== 'www') {
      return parts[0];
    }
    return null;
  };

  const subdomain = getSubdomain(hostname);

  // Define logo mapping
  const logoMapping = {
    mindsbourg,
    vnips,
    default: nc,
  };

  // Only render subdomain logo if it exists in the mapping
  const shouldRenderSubdomainLogo = subdomain && logoMapping[subdomain];

  return (
    <LogoContainer href="/dashboard/#/">
      {shouldRenderSubdomainLogo && (
        <LogoImage 
          src={logoMapping[subdomain]} 
          alt={`${subdomain} Logo`} 
          height="28px" 
        />
      )}
      <LogoImage src={nc} alt="Logo" height="25px" />
      <BetaTag>Beta</BetaTag>
    </LogoContainer>
  );
};

export default Logo;