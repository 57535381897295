// EditorJsDiff.js
import { diffWords, diffLines } from "diff";

class EditorJsDiff {
  constructor() {
    this.blockTypeHandlers = {
      paragraph: this.diffParagraph.bind(this),
      header: this.diffHeader.bind(this),
      list: this.diffList.bind(this),
      code: this.diffCode.bind(this),
      image: this.diffImage.bind(this),
      table: this.diffTable.bind(this),
      quote: this.diffQuote.bind(this),
      checklist: this.diffChecklist.bind(this),
      delimiter: this.diffDelimiter.bind(this),
      embed: this.diffEmbed.bind(this),
      raw: this.diffRaw.bind(this),
    };
  }

  /**
   * Main method to compare two Editor.js contents
   */
  compareContent(oldContent, newContent) {
    const oldBlocks = oldContent?.blocks || [];
    const newBlocks = newContent?.blocks || [];

    // Track positions
    const oldPositions = new Map();
    const newPositions = new Map();

    oldBlocks.forEach((block, index) => oldPositions.set(block.id, index));
    newBlocks.forEach((block, index) => newPositions.set(block.id, index));

    const diffResult = {
      added: [],
      removed: [],
      modified: [],
      unchanged: [],
      reordered: [],
    };

    const processedOldBlocks = new Set();
    const processedNewBlocks = new Set();

    // First pass: Find matches and modifications
    oldBlocks.forEach((oldBlock, oldIndex) => {
      const newIndex = newBlocks.findIndex((block) => block.id === oldBlock.id);

      if (newIndex !== -1) {
        const newBlock = newBlocks[newIndex];
        processedOldBlocks.add(oldBlock.id);
        processedNewBlocks.add(newBlock.id);

        const blockDiff = this.compareBlocks(oldBlock, newBlock);

        if (oldIndex !== newIndex) {
          diffResult.reordered.push({
            block: newBlock,
            oldPosition: oldIndex,
            newPosition: newIndex,
            changes: blockDiff.hasChanges ? blockDiff : null,
          });
        } else if (blockDiff.hasChanges) {
          diffResult.modified.push(blockDiff);
        } else {
          diffResult.unchanged.push({
            block: oldBlock,
            position: oldIndex,
          });
        }
      }
    });

    // Second pass: Find additions and deletions
    oldBlocks.forEach((block) => {
      if (!processedOldBlocks.has(block.id)) {
        diffResult.removed.push({
          block,
          position: oldPositions.get(block.id),
        });
      }
    });

    newBlocks.forEach((block) => {
      if (!processedNewBlocks.has(block.id)) {
        diffResult.added.push({
          block,
          position: newPositions.get(block.id),
        });
      }
    });

    return this.sortDiffResult(diffResult, oldBlocks, newBlocks);
  }

  /**
   * Sort diff results by position
   */

  sortDiffResult(diffResult, oldBlocks, newBlocks) {
    const orderedChanges = [];
    const processedIds = new Set();
  
    // Iterate over each block in the original (old) order.
    oldBlocks.forEach((oldBlock, index) => {
      // Look for a diff for this block in the diff result arrays.
      const diff =
        diffResult.modified.find(d => d.old.id === oldBlock.id) ||
        diffResult.reordered.find(d => {
          // Depending on how your diff was structured, a reordered block might be nested under diff.block.
          return d.block && d.block.id === oldBlock.id;
        }) ||
        diffResult.unchanged.find(d => d.block.id === oldBlock.id) ||
        diffResult.removed.find(d => d.block.id === oldBlock.id);
  
      if (diff) {
        orderedChanges.push({
          ...diff,
          // Force the sort position to the original (old) position.
          sortPosition: index
        });
        processedIds.add(oldBlock.id);
      }
    });
  
    // Now process any added blocks that did not exist in the old document.
    newBlocks.forEach((newBlock, index) => {
      if (!processedIds.has(newBlock.id)) {
        const addedDiff = diffResult.added.find(d => d.block.id === newBlock.id);
        if (addedDiff) {
          // Using a fractional offset (index + 0.5) to place added blocks between original ones,
          // or you could simply append them by using newBlock's order.
          orderedChanges.push({
            ...addedDiff,
            sortPosition: index + 0.5
          });
        }
      }
    });
  
    // Finally, sort the combined list by the sortPosition.
    orderedChanges.sort((a, b) => a.sortPosition - b.sortPosition);
    diffResult.orderedChanges = orderedChanges;
    return diffResult;
  }

    /**
   * Helper method to find the original position of a block by ID
   */
    findOriginalPosition(blockId, diffResult) {
        // First check in unchanged blocks
        const unchangedBlock = diffResult.unchanged.find(change => change.block.id === blockId);
        if (unchangedBlock) {
          return unchangedBlock.position;
        }
    
        // Check in removed blocks
        const removedBlock = diffResult.removed.find(change => change.block.id === blockId);
        if (removedBlock) {
          return removedBlock.position;
        }
    
        // Check in reordered blocks (using old position)
        const reorderedBlock = diffResult.reordered.find(change => change.block.id === blockId);
        if (reorderedBlock) {
          return reorderedBlock.oldPosition;
        }
    
        // If not found, return Infinity to place at the end
        return Infinity;
      }

  /**
   * Compare individual blocks
   */
  compareBlocks(oldBlock, newBlock) {
    if (oldBlock.type !== newBlock.type) {
      return {
        hasChanges: true,
        type: "block_type_changed",
        old: oldBlock,
        new: newBlock,
      };
    }

    const handler = this.blockTypeHandlers[oldBlock.type];
    if (handler) {
      return handler(oldBlock, newBlock);
    }

    return {
      hasChanges: JSON.stringify(oldBlock) !== JSON.stringify(newBlock),
      type: "unknown_block_changed",
      old: oldBlock,
      new: newBlock,
    };
  }

  /**
   * Block-specific diff handlers
   */
  diffParagraph(oldBlock, newBlock) {
    const changes = diffWords(oldBlock.data.text, newBlock.data.text);
    const hasChanges = changes.some((change) => change.added || change.removed);

    return {
      hasChanges,
      type: "paragraph",
      blockId: oldBlock.id,
      changes,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffHeader(oldBlock, newBlock) {
    const textChanges = diffWords(oldBlock.data.text, newBlock.data.text);
    const levelChanged = oldBlock.data.level !== newBlock.data.level;
    const hasChanges =
      levelChanged ||
      textChanges.some((change) => change.added || change.removed);

    return {
      hasChanges,
      type: "header",
      blockId: oldBlock.id,
      levelChanged,
      textChanges,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffList(oldBlock, newBlock) {
    const oldItems = oldBlock.data.items;
    const newItems = newBlock.data.items;
    const itemChanges = [];

    const maxLength = Math.max(oldItems.length, newItems.length);
    for (let i = 0; i < maxLength; i++) {
      const oldItem = oldItems[i] || "";
      const newItem = newItems[i] || "";
      const itemDiff = diffWords(oldItem, newItem);

      itemChanges.push({
        index: i,
        changes: itemDiff,
        hasChanges: itemDiff.some((change) => change.added || change.removed),
      });
    }

    return {
      hasChanges: itemChanges.some((item) => item.hasChanges),
      type: "list",
      blockId: oldBlock.id,
      styleChanged: oldBlock.data.style !== newBlock.data.style,
      itemChanges,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffCode(oldBlock, newBlock) {
    const codeChanges = diffLines(oldBlock.data.code, newBlock.data.code);
    const languageChanged = oldBlock.data.language !== newBlock.data.language;

    return {
      hasChanges:
        languageChanged ||
        codeChanges.some((change) => change.added || change.removed),
      type: "code",
      blockId: oldBlock.id,
      languageChanged,
      codeChanges,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffImage(oldBlock, newBlock) {
    return {
      hasChanges:
        oldBlock.data.url !== newBlock.data.url ||
        oldBlock.data.caption !== newBlock.data.caption,
      type: "image",
      blockId: oldBlock.id,
      urlChanged: oldBlock.data.url !== newBlock.data.url,
      captionChanges:
        oldBlock.data.caption !== newBlock.data.caption
          ? diffWords(
              oldBlock.data.caption || "",
              newBlock.data.caption || ""
            )
          : null,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffTable(oldBlock, newBlock) {
    const oldContent = oldBlock.data.content;
    const newContent = newBlock.data.content;
    const cellChanges = [];

    const maxRows = Math.max(oldContent.length, newContent.length);
    const maxCols = Math.max(
      ...oldContent.map((row) => row.length),
      ...newContent.map((row) => row.length)
    );

    for (let i = 0; i < maxRows; i++) {
      const rowChanges = [];
      for (let j = 0; j < maxCols; j++) {
        const oldCell = (oldContent[i] || [])[j] || "";
        const newCell = (newContent[i] || [])[j] || "";
        const cellDiff = diffWords(oldCell, newCell);

        rowChanges.push({
          position: [i, j],
          changes: cellDiff,
          hasChanges: cellDiff.some((change) => change.added || change.removed),
        });
      }
      cellChanges.push(rowChanges);
    }

    return {
      hasChanges: cellChanges.some((row) =>
        row.some((cell) => cell.hasChanges)
      ),
      type: "table",
      blockId: oldBlock.id,
      cellChanges,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffQuote(oldBlock, newBlock) {
    const textChanges = diffWords(oldBlock.data.text, newBlock.data.text);
    const captionChanges = diffWords(
      oldBlock.data.caption || "",
      newBlock.data.caption || ""
    );

    return {
      hasChanges:
        textChanges.some((change) => change.added || change.removed) ||
        captionChanges.some((change) => change.added || change.removed),
      type: "quote",
      blockId: oldBlock.id,
      textChanges,
      captionChanges,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffChecklist(oldBlock, newBlock) {
    const oldItems = oldBlock.data.items;
    const newItems = newBlock.data.items;
    const itemChanges = [];

    const maxLength = Math.max(oldItems.length, newItems.length);
    for (let i = 0; i < maxLength; i++) {
      const oldItem = oldItems[i] || { text: "", checked: false };
      const newItem = newItems[i] || { text: "", checked: false };
      const textDiff = diffWords(oldItem.text, newItem.text);

      itemChanges.push({
        index: i,
        textChanges: textDiff,
        checkedChanged: oldItem.checked !== newItem.checked,
        hasChanges:
          textDiff.some((change) => change.added || change.removed) ||
          oldItem.checked !== newItem.checked,
      });
    }

    return {
      hasChanges: itemChanges.some((item) => item.hasChanges),
      type: "checklist",
      blockId: oldBlock.id,
      itemChanges,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffDelimiter(oldBlock, newBlock) {
    return {
      hasChanges: false,
      type: "delimiter",
      blockId: oldBlock.id,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffEmbed(oldBlock, newBlock) {
    return {
      hasChanges:
        oldBlock.data.service !== newBlock.data.service ||
        oldBlock.data.source !== newBlock.data.source ||
        oldBlock.data.embed !== newBlock.data.embed ||
        oldBlock.data.width !== newBlock.data.width ||
        oldBlock.data.height !== newBlock.data.height ||
        oldBlock.data.caption !== newBlock.data.caption,
      type: "embed",
      blockId: oldBlock.id,
      old: oldBlock,
      new: newBlock,
    };
  }

  diffRaw(oldBlock, newBlock) {
    const changes = diffLines(oldBlock.data.html, newBlock.data.html);
    return {
      hasChanges: changes.some((change) => change.added || change.removed),
      type: "raw",
      blockId: oldBlock.id,
      changes,
      old: oldBlock,
      new: newBlock,
    };
  }

  /**
   * Render diff results as HTML
   */
  renderDiffHtml(diffResult) {
    let html = '<div class="editor-diff">';

    diffResult.orderedChanges.forEach((change) => {
      if ("block" in change) {
        // Handle reordered blocks
        if ("oldPosition" in change) {
          const moveIndicator =
            change.newPosition > change.oldPosition ? "↓" : "↑";
          const changeClass = change.changes
            ? "diff-reordered diff-modified"
            : "diff-reordered";

          html += `<div class="diff-block ${changeClass}">
            <div class="diff-marker">${moveIndicator}</div>
            <div class="diff-content">
              ${
                change.changes
                  ? this.renderModifiedBlock(change.changes)
                  : this.renderBlock(change.block)
              }
            </div>
            <div class="diff-move-info">Moved from position ${
              change.oldPosition + 1
            } to ${change.newPosition + 1}</div>
          </div>`;
        }
        // Handle added blocks
        else if (diffResult.added.includes(change)) {
          html += `<div class="diff-block diff-added">
            <div class="diff-marker">+</div>
            <div class="diff-content added">${this.renderBlock(
              change.block
            )}</div>
          </div>`;
        }
        // Handle removed blocks
        else if (diffResult.removed.includes(change)) {
          html += `<div class="diff-block diff-removed">
            <div class="diff-marker">-</div>
            <div class="diff-content removed">${this.renderBlock(
              change.block
            )}</div>
          </div>`;
        }
        // Handle unchanged blocks
        else {
          html += `<div class="diff-block">
            <div class="diff-content">${this.renderBlock(change.block)}</div>
          </div>`;
        }
      }
      // Handle modified blocks
      else {
        html += `<div class="diff-block diff-modified">
          <div class="diff-content">${this.renderModifiedBlock(change)}</div>
        </div>`;
      }
    });

    html += "</div>";
    return html;
  }

  /**
   * Render a modified block with inline diff highlights
   */
  renderModifiedBlock(blockDiff) {
    switch (blockDiff.type) {
      case "paragraph":
      case "header":
        return blockDiff.changes
          .map((change) => {
            if (change.added) {
              return `<span class="diff-added">${change.value}</span>`;
            }
            if (change.removed) {
              return `<span class="diff-removed">${change.value}</span>`;
            }
            return change.value;
          })
          .join("");

      case "list":
        return blockDiff.itemChanges
          .map((item) => {
            const itemHtml = item.changes
              .map((change) => {
                if (change.added) {
                  return `<span class="diff-added">${change.value}</span>`;
                }
                if (change.removed) {
                  return `<span class="diff-removed">${change.value}</span>`;
                }
                return change.value;
              })
              .join("");
            return `<li>${itemHtml}</li>`;
          })
          .join("");

      case "code":
        return blockDiff.codeChanges
          .map((change) => {
            if (change.added) {
              return `<div class="diff-added">${change.value}</div>`;
            }
            if (change.removed) {
              return `<div class="diff-removed">${change.value}</div>`;
            }
            return `<div>${change.value}</div>`;
          })
          .join("");

      case "table":
        let tableHtml = "<table>";
        blockDiff.cellChanges.forEach((row) => {
          tableHtml += "<tr>";
          row.forEach((cell) => {
            tableHtml += "<td>";
            if (cell.hasChanges) {
              cell.changes.forEach((change) => {
                if (change.added) {
                  tableHtml += `<span class="diff-added">${change.value}</span>`;
                } else if (change.removed) {
                  tableHtml += `<span class="diff-removed">${change.value}</span>`;
                } else {
                  tableHtml += change.value;
                }
              });
            } else {
              tableHtml += cell.changes[0].value;
            }
            tableHtml += "</td>";
          });
          tableHtml += "</tr>";
        });
        tableHtml += "</table>";
        return tableHtml;

      case "quote":
        const textHtml = blockDiff.textChanges
          .map((change) => {
            if (change.added) {
              return `<span class="diff-added">${change.value}</span>`;
            }
            if (change.removed) {
              return `<span class="diff-removed">${change.value}</span>`;
            }
            return change.value;
          })
          .join("");

        const captionHtml = blockDiff.captionChanges
          .map((change) => {
            if (change.added) {
              return `<span class="diff-added">${change.value}</span>`;
            }
            if (change.removed) {
              return `<span class="diff-removed">${change.value}</span>`;
            }
            return change.value;
          })
          .join("");

        return `<blockquote>
          <p>${textHtml}</p>
          <footer>${captionHtml}</footer>
        </blockquote>`;

      case "checklist":
        return blockDiff.itemChanges
          .map((item) => {
            const checkboxClass = item.checkedChanged ? "diff-modified" : "";
            const checked = blockDiff.new.data.items[item.index]?.checked;

            return `<div class="checklist-item ${checkboxClass}">
            <input type="checkbox" ${checked ? "checked" : ""} disabled>
            <span>${item.textChanges
              .map((change) => {
                if (change.added) {
                  return `<span class="diff-added">${change.value}</span>`;
                }
                if (change.removed) {
                  return `<span class="diff-removed">${change.value}</span>`;
                }
                return change.value;
              })
              .join("")}</span>
          </div>`;
          })
          .join("");

      default:
        return this.renderBlock(blockDiff.new);
    }
  }

  /**
   * Render a single block as HTML
   */
  renderBlock(block) {
    switch (block.type) {
      case "paragraph":
        return `<p>${block.data.text}</p>`;

      case "header":
        return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;

      case "list":
        const listType = block.data.style === "ordered" ? "ol" : "ul";
        return `<${listType}>${block.data.items
          .map((item) => `<li>${item}</li>`)
          .join("")}</${listType}>`;

      case "code":
        return `<pre><code class="language-${block.data.language}">${block.data.code}</code></pre>`;

      case "image":
        return `<figure>
          <img src="${block.data.url}" alt="${block.data.caption}">
          ${
            block.data.caption
              ? `<figcaption>${block.data.caption}</figcaption>`
              : ""
          }
        </figure>`;

      case "table":
        return `<table>${block.data.content
          .map(
            (row) =>
              `<tr>${row.map((cell) => `<td>${cell}</td>`).join("")}</tr>`
          )
          .join("")}</table>`;

      case "quote":
        return `<blockquote>
          <p>${block.data.text}</p>
          ${block.data.caption ? `<footer>${block.data.caption}</footer>` : ""}
        </blockquote>`;

      case "checklist":
        return `<div class="checklist">
          ${block.data.items
            .map(
              (item) => `
            <div class="checklist-item">
              <input type="checkbox" ${item.checked ? "checked" : ""} disabled>
              <span>${item.text}</span>
            </div>
          `
            )
            .join("")}
        </div>`;

      case "delimiter":
        return "<hr>";

      case "embed":
        return `<div class="embed">
          <iframe 
            src="${block.data.embed}"
            width="${block.data.width}"
            height="${block.data.height}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          ${
            block.data.caption
              ? `<figcaption>${block.data.caption}</figcaption>`
              : ""
          }
        </div>`;

      case "raw":
        return block.data.html;

      default:
        return `<div>Unsupported block type: ${block.type}</div>`;
    }
  }
}

// Export the class
export default EditorJsDiff;
