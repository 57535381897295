import React, { useEffect, useRef, useState } from "react";

import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  Grid,
  Group,
  Menu,
  Modal,
  ScrollArea,
  Stack,
  Tabs,
  Text,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconCheck,
  IconChecks,
  IconEye,
  IconPencil,
  IconPencilPlus,
  IconTrashX,
  IconX,
} from "@tabler/icons-react";

import { IconEyeClosed } from "@tabler/icons-react";

import AiChat from "./AiChat";
import { useDisclosure } from "@mantine/hooks";
import fetchApi from "../Lib/api";
import Notification from "../Lib/Notification";
import Markdown from "../Lib/Markdown";
import GoogleDrivePickerAndUploader from "../Lib/FilesPickerAndUploader";
import FileList from "../Lib/FilesDisplay";
import FeatureButton from "../../stories/FeatureButton";

export default function Editor({
  owner,
  member,
  role,
  programs,
  selectedPrograms,
  close,
  setContent,
  content,
  privacy,
  setPrivacy,
  files,
  setFiles,
  handleSave,
  members,
}) {
  const [index, setIndex] = useState(selectedPrograms.sid);
  const [loading, setLoading] = useState(false);

  return (
    <Stack className="h-full w-full py-4">
      {selectedPrograms && (
        <Group align="start">
          <Title order={3} className="text-gray-700 mb-5">
            {programs.categories[selectedPrograms.cid].name}
          </Title>
          <AssignMembers
            canAssign={owner || role < 2}
            categoryId={programs.categories[selectedPrograms.cid].id}
            members={members}
            assign_members={
              programs.categories[selectedPrograms.cid].assigned_users
            }
          />
        </Group>
      )}

      <Tabs
        variant="pills"
        className="w-full"
        defaultValue={
          programs.categories[selectedPrograms.cid].sub_categories[
            selectedPrograms.sid
          ].name
        }
      >
        <Tabs.List className="bg-gray-100">
          {programs.categories[selectedPrograms.cid].sub_categories.map(
            (tab, i) => {
              if (
                tab.is_active &&
                (owner ||
                  (tab.privacy !== "Only me" && member) ||
                  (tab.privacy === "Everyone" && tab.response))
              )
                return (
                  <Tabs.Tab
                    onClick={() => setIndex(i)}
                    icon={tab.response ? <IconChecks /> : <></>}
                    key={tab.name}
                    value={tab.name}
                    className="font-bold"
                  >
                    {tab.name}
                  </Tabs.Tab>
                );
            }
          )}
        </Tabs.List>

        {programs.categories[selectedPrograms.cid].sub_categories.map(
          (tab, index) => {
            if (
              tab.is_active &&
              (owner ||
                (tab.privacy !== "Only me" && member) ||
                (tab.privacy === "Everyone" && tab.response))
            )
              return (
                <Tabs.Panel key={tab.id} value={tab.name}>
                  <Grid className="w-full" columns={25}>
                    <Grid.Col lg={14} order={1} orderSm={1} orderLg={1}>
                      <Stack>
                        <Markdown
                          value={(content && content[index].response) || ""}
                          visibleDragbar={false}
                          preview={"edit"}
                          hideToolbar={false}
                          height={500}
                          setValue={(e) => {
                            setContent((p) => {
                              const arr = [...p];
                              arr[index].response = e;
                              arr[index].touched = true;
                              return arr;
                            });
                          }}
                        />
                        <GoogleDrivePickerAndUploader
                          clientId={clientId}
                          apiKey={apiKey}
                          setFiles={setFiles}
                          files={files}
                          index={index}
                          dropboxAppKey={dropboxAppKey}
                          total_files_size={tab.total_files_size}
                        >
                          <ScrollArea h={160} type="auto">
                            <FileList fileRecords={files[index]} />
                            <FileList
                              files={tab.files}
                              fileRecords={tab.file_records}
                            />
                          </ScrollArea>
                        </GoogleDrivePickerAndUploader>
                      </Stack>
                    </Grid.Col>
                    <Grid.Col lg={11} order={1} orderSm={2} orderLg={2}>
                      <Stack
                        h="100%"
                        justify="space-between"
                        className="flex flex-col"
                      >
                        <Guide tab={tab} />

                        <AiChat />
                      </Stack>
                    </Grid.Col>
                  </Grid>
                </Tabs.Panel>
              );
          }
        )}
      </Tabs>
      <Group
        position="center"
        // className="overflow-x-auto flex-nowrap lg:flex-wrap"
      >
        {(owner || role < 1) && (
          <Menu
            classNames="min-w-5"
            trigger="hover"
            shadow="md"
            size="md"
            leftIcon={
              privacy !== "Only me" ? (
                <IconEye size="1rem" />
              ) : (
                <IconEyeClosed size="1rem" />
              )
            }
          >
            <Menu.Target>
              <Button variant="default" className="w-fit">
                Privacy
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={(e) =>
                  setPrivacy((p) => {
                    const arr = [...p];
                    arr[index] = "Everyone";
                    return arr;
                  })
                }
              >
                Everyone
              </Menu.Item>
              <Menu.Item
                onClick={(e) =>
                  setPrivacy((p) => {
                    const arr = [...p];
                    arr[index] = "Members";
                    return arr;
                  })
                }
              >
                Members
              </Menu.Item>
              <Menu.Item
                onClick={(e) =>
                  setPrivacy((p) => {
                    const arr = [...p];
                    arr[index] = "Only me";
                    return arr;
                  })
                }
              >
                Only me
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}

        <Button
          variant="default"
          size="md"
          leftIcon={<IconTrashX size="1rem" />}
          onClick={close}
        >
          Leave without saving
        </Button>
        <Button
          onClick={() => {
            programs.categories[selectedPrograms.cid].sub_categories.forEach(
              (element, i) => {
                if (element.is_active) handleSave(i, setLoading);
              }
            );
          }}
          variant="default"
          size="md"
          leftIcon={<IconPencilPlus size="1rem" />}
          loading={loading}
        >
          Save changes
        </Button>
      </Group>
    </Stack>
  );
}

export const AssignMembers = ({
  members,
  assign_members,
  categoryId,
  canAssign,
}) => {
  const [otherMembers, setOtherMembers] = useState([]);
  const [assignMembers, setAssignMembers] = useState(assign_members);
  const [opened, { open, close }] = useDisclosure(false);
  const [notification, setNotification] = useState(null);

  const handleRequest = async (accepted, categoryId, userId) => {
    try {
      const action = accepted ? "assign_member" : "remove_member";
      const method = accepted ? "POST" : "DELETE";
      const startupId = 0;

      const response = await fetchApi(
        `/startup_entities/${startupId}/categories/${categoryId}/${action}`,
        method,
        { user_id: userId }
      );

      if (response.ok) {
        const data = await response.json();
        setNotification({ type: "success", content: data.message });
        console.log(data); // Assuming the response contains a message
        let memberToRemove = null;
        if (accepted) {
          setAssignMembers((p) => p.filter((a) => a.user.id != userId));
          const updatedOtherMembers = otherMembers.filter((member) => {
            if (member.id === userId) {
              memberToRemove = member;
              return false;
            }
            return true;
          });

          setOtherMembers(updatedOtherMembers);
          setAssignMembers((p) => [...p, { user: memberToRemove }]);
        } else {
          const updatedAssignMembers = assignMembers.filter((member) => {
            if (member.user.id === userId) {
              memberToRemove = member.user;
              return false;
            }
            return true;
          });
          setAssignMembers(updatedAssignMembers);
          setOtherMembers((p) => [...p, memberToRemove]);
        }
      } else {
        const errorData = await response.json();
        setNotification({ type: "failure", content: errorData.error });
        console.error(errorData.error); // Assuming the response contains an error message
        // Handle the error accordingly
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setOtherMembers(
      members.filter(
        (member) =>
          !assign_members.some(
            (assignMember) => assignMember.user.id === member.id
          )
      )
    );
  }, []);

  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          size="lg"
          onClose={close}
          withCloseButton={false}
          withinPortal
        >
          {notification && <Notification {...notification} />}
          <Card radius="md">
            <Card.Section withBorder inheritPadding py="xs">
              <Text fw={500}>Assigned Members</Text>
            </Card.Section>
            <div className="flow-root">
              <ul
                role="list"
                className="divide-y divide-gray-200 dark:divide-gray-700 list-none"
              >
                {assignMembers.map(({ user }, i) => (
                  <li className="py-3 sm:py-4" key={user.email}>
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Avatar
                          src={
                            user.avatar_url ||
                            "https://placehold.co/600x400?text=No Image"
                          }
                          mx="auto"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>

                      <div className="flex-1 min-w-0">
                        <a href={`/profile/${user.id}`}>
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {`${user.first_name} ${user.last_name}`}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            {user.email}
                          </p>
                        </a>
                      </div>

                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        <Group gap={0} justify="flex-end">
                          <Button
                            onClick={() =>
                              handleRequest(false, categoryId, user.id)
                            }
                            variant="default"
                            fullWidth
                            className="w-fit"
                          >
                            Unassign user
                          </Button>
                        </Group>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <Card.Section withBorder inheritPadding pt={25} pb={10}>
              <Text fw={500}>Other Members</Text>
            </Card.Section>
            <div className="flow-root">
              <ul
                role="list"
                className="divide-y divide-gray-200 dark:divide-gray-700 list-none"
              >
                {otherMembers.map((user, i) => (
                  <li className="py-3 sm:py-4" key={user.email}>
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <Avatar
                          src={
                            user.avatar_url ||
                            "https://placehold.co/600x400?text=No Image"
                          }
                          mx="auto"
                          className="w-8 h-8 rounded-full"
                        />
                      </div>

                      <div className="flex-1 min-w-0">
                        <a href={`/profile/user/${user.id}`}>
                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {`${user.first_name} ${user.last_name}`}
                          </p>
                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                            {user.email}
                          </p>
                        </a>
                      </div>

                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        <Group gap={0} justify="flex-end">
                          <Button
                            onClick={() =>
                              handleRequest(true, categoryId, user.id)
                            }
                            variant="default"
                            fullWidth
                            className="w-fit"
                          >
                            Assign user
                          </Button>
                        </Group>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Card>
        </Modal>
      )}
      {canAssign && (
        <div style={{ marginLeft: "auto", display: "flex", gap: "4px" }}>
          <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="sm">
              {assignMembers.map(({ user }) => (
                <Tooltip
                  key={user.id}
                  label={`${user.first_name} ${user.last_name}`}
                  withArrow
                >
                  <Avatar src={user.avatar_url} radius="xl" size={30} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </Tooltip.Group>
          <FeatureButton action={open} buttonText="Assign members" />
        </div>
      )}
    </>
  );
};
const Guide = ({ tab }) => {
  const [showTools, setShowTools] = useState(false);
  const [guide, setGuide] = useState(tab.guide);

  const handleUpdate = async () => {
    try {
      const response = await fetchApi(
        `/sub_categories/${tab.id}/update_guide`,
        "PATCH",
        {
          sub_category: { guide },
        }
      );

      if (response.ok) {
        const res = await response.json();
        setShowTools(false);
        console.log(res);
      } else {
        const msg = (await response.json()).error;
        // setNotification({ type: "failure", content: msg });
        console.error(msg);
      }
    } catch (error) {
      console.error("Error during updating guide:", error);
    }
  };

  return (
    <Card radius="md" p={0} withBorder className="px-4 mt-3 h-fit">
      <Group className="pt-4 pl-4">
        <Title order={4} size="h4" fw={700}>
          Guide
        </Title>
        {tab.editable && (
          <ActionIcon
            component="a"
            href={`#`}
            size={28}
            variant="default"
            aria-label="Edit guide"
            onClick={() => setShowTools(!showTools)}
          >
            {showTools ? (
              <IconX style={{ width: rem(18), height: rem(18) }} stroke={2.5} />
            ) : (
              <IconPencil
                style={{ width: rem(18), height: rem(18) }}
                stroke={2.5}
              />
            )}
          </ActionIcon>
        )}
        {showTools && (
          <ActionIcon
            component="a"
            href={`#`}
            size={28}
            variant="default"
            aria-label="Edit guide"
            onClick={handleUpdate}
          >
            <IconCheck
              style={{ width: rem(18), height: rem(18) }}
              stroke={2.5}
            />
          </ActionIcon>
        )}
      </Group>
      <Markdown
        value={guide || ""}
        visibleDragbar={false}
        preview={"preview"}
        hideToolbar={true}
        height={300}
        setValue={setGuide}
      />
    </Card>
  );
};
