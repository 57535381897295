import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Layout from "../Lib/Layout";
import UserProvider from "../Lib/UserContext";
import {
  IconBuildingSkyscraper,
  IconChartLine,
  IconScale,
  IconTrophy,
} from "@tabler/icons-react";
import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Group,
  Menu,
  Modal,
  Stack,
  Textarea,
  Tooltip,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconArrowLeft,
  IconBulb,
  IconExternalLink,
  IconRocket,
} from "@tabler/icons-react";
import fetchApi from "../Lib/api";
import { useDisclosure } from "@mantine/hooks";
import QueryProvider from "../Lib/QueryClientProvider";
import useUserStore from "../../stores/userStore";
import { IconLayersDifference } from "@tabler/icons-react";
import { IconCashBanknote } from "@tabler/icons-react";
import SignUpModal from "../Authentication/SignUpModal";

const Title = styled.h2`
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-body-2);
  color: var(--color-background-card);
  line-height: 26px;
`;

const Description = styled.p`
  margin-top: 4px;
  color: var(--color-background-main);
`;

function IdeaValidation({}) {
  const [idea, setIdea] = useState("");
  const [validateToken, setValidateToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [ideaShort, setIdeaShort] = useState("");
  const [checked, setChecked] = useState(true);
  const { user = null } = useUserStore((state) => state.user || {});
  const [evaluation, setEvaluation] = useState({});
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSignUpModal(!user);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const onSubmit = async () => {
    if (idea.length < 20) {
      setIdeaShort("Idea empty or too short to evaluate.");
      return;
    }
    try {
      setLoading(true);
      const res = await fetchApi("/chat", "POST", {
        message: idea,
        temporary: !checked,
      });

      if (res.ok) {
        const responseData = await res.json();
        const { tokens = {}, ...evaluation } = responseData;
        console.log(evaluation, tokens);
        setValidateToken(tokens.startup_creation_token);
        setEvaluation(evaluation);
      } else {
        console.error("HTTP error! Failed to validate idea");
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          size="lg"
          withCloseButton={false}
        >
          <div class="p-4 overflow-y-auto">
            <p class="mt-1 text-gray-8000">
              You will need to complete your profile to have access to this
              feature.
            </p>
          </div>
          <div class="flex justify-end items-center gap-x-2 py-3 px-4">
            <Button onClick={close} variant="default" className=" px-12">
              Cancel
            </Button>
            <Button
              href="/onboarding"
              component="a"
              variant="light"
              color="indigo"
              className="text-blue-700 px-12"
            >
              Complete Profile
            </Button>
          </div>
        </Modal>
      )}
      {showSignUpModal && (
        <Modal
          opened={showSignUpModal}
          transitionProps={{
            transition: "fade",
            duration: 600,
            timingFunction: "linear",
          }}
          withCloseButton={false}
          closeOnClickOutside={false}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <SignUpModal />
        </Modal>
      )}
      <main className="max-w-[900px] lg:pt-10 p-4 pt-6 mx-2  sm:mx-auto  space-x-2">
        <Anchor onClick={() => window.history.back()} target="_blank">
          <Group className="mb-2" spacing={4} h>
            <IconArrowLeft size={18} color="#1c7ed6" />
            Go back
          </Group>
        </Anchor>
        <Stack>
          <div>
            <Title>
              Explore the potential of your business ideas with our Idea
              Validation Tool!
            </Title>
            <Description>
              Designed to objectively assess the viability of your concepts in
              today's competitive market, this tool evaluates key factors such
              as market demand, competitive advantage, feasibility, scalability,
              and revenue potential. Each idea is scored out of 100, with
              detailed feedback provided to help refine your strategy. Embrace
              this opportunity to innovate and enhance your business ideas,
              guided by clear, actionable insights.
            </Description>
          </div>
          <Stack>
            <div>
              <Alert
                icon={<IconAlertCircle size="1.5rem" />}
                variant="outline"
                color="green"
                py={4}
                style={{
                  maxWidth: "fit-content",
                  marginLeft: "auto",
                  marginBottom: "4px",
                }}
              >
                An ideal score is 80 and above.
              </Alert>

              <Textarea
                placeholder="Pitch your idea here..."
                error={ideaShort}
                size="lg"
                withAsterisk
                minRows={10}
                style={{ outline: "none", border: "none" }}
                value={idea}
                onChange={(event) => {
                  if (ideaShort) setIdeaShort("");
                  setIdea(event.currentTarget.value.slice(0, 400));
                }}
              />

              <Group position="apart">
                <Checkbox
                  checked={checked}
                  onChange={(event) => setChecked(event.currentTarget.checked)}
                  label="You agree to all our terms and conditions "
                />

                <div className="flex justify-between">
                  <p>({idea.length}/400)</p>
                </div>
              </Group>
            </div>
            <Group noWrap>
              <Stack spacing={2} className="w-full lg:w-1/3 ">
                <Button
                  onClick={onSubmit}
                  variant="default"
                  fullWidth
                  className="flex-grow-0 font-light rounded-lg border border-transparent bg-green-800 text-white hover:bg-green-600 disabled:opacity-50 disabled:pointer-events-none expert"
                  leftIcon={<IconBulb size="1.5rem" />}
                  loading={loading}
                  size="xl"
                >
                  Validate Idea
                </Button>
              </Stack>
              {evaluation &&
                !isNaN(+evaluation?.final_score?.split("/")[0]) && (
                  <Tooltip
                    color="indigo"
                    label="You need an Idea Score of 50 or higher to create a project."
                    disabled={+evaluation.final_score.split("/")[0] >= 50}
                    withinPortal
                  >
                    <Button
                      variant="default"
                      fullWidth
                      className="w-full lg:w-1/3 flex-grow-0 font-light rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-800 disabled:opacity-50 disabled:pointer-events-none"
                      leftIcon={<IconBuildingSkyscraper size="1.5rem" />}
                      size="xl"
                      component={user?.profile_complete ? "a" : "button"}
                      href={
                        user?.profile_complete && validateToken
                          ? `/startup_entities/new/${validateToken}`
                          : undefined
                      }
                      onClick={!user?.profile_complete ? open : undefined}
                    >
                      Create Startup
                    </Button>
                  </Tooltip>
                )}
              <Button
                variant="default"
                fullWidth
                className="w-full lg:w-1/3 flex-grow-0 font-light border border-transparent bg-blue-600 text-white hover:bg-blue-600 disabled:opacity-50 disabled:pointer-events-none"
                leftIcon={<IconRocket size="1.5rem" />}
                size="xl"
                component={user?.profile_complete ? "a" : "button"}
                href={
                  user?.profile_complete ? "/project_entities/new" : undefined
                }
                onClick={!user?.profile_complete ? open : undefined}
              >
                Create Project
              </Button>
            </Group>
          </Stack>
          <IdeaValidationSummary evaluation={evaluation} />
        </Stack>
      </main>
    </>
  );
}

const IdeaValidationSummary = ({ evaluation }) => {
  return (
    <div className="space-y-6 mt-8">
      {/* Overall Score and Feedback Section */}
      <div className="flex justify-between items-start gap-6">
        {/* Feedback Section */}
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-gray-600">Feedback</h3>
          <div className="bg-gray-100 p-4 rounded-md space-y-4">
            <div>
              <strong className="text-gray-700">Strength:</strong>
              <p className="text-gray-500">
                {evaluation?.overall_feedback?.strength ||
                  "This will highlight the strengths of your idea, like addressing a unique market need."}
              </p>
            </div>
            <div>
              <strong className="text-gray-700">Improvement:</strong>
              <p className="text-gray-500">
                {evaluation?.overall_feedback?.improvement ||
                  "Suggestions for improvement will be shown here, such as refining your value proposition."}
              </p>
            </div>
            <div>
              <strong className="text-gray-700">Reference Trends:</strong>
              <p className="text-gray-500">
                {evaluation?.overall_feedback?.reference_trends ||
                  "Relevant market trends will appear here, like rising demand for tech-driven solutions."}
              </p>
            </div>
          </div>
        </div>

        <div className="flex-1">
          {/* Overall Score */}
          <div>
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-600">
                Overall Score
              </h2>
              <span className="inline text-2xl font-bold text-indigo-600">
                {evaluation.final_score || "0/100"}
              </span>
            </div>
          </div>

          {/* Details Section */}
          <div className="space-y-4 flex-1 mt-4">
            <h2 className="text-lg font-semibold text-gray-600">Details</h2>

            {/* Market Demand */}
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <IconChartLine className="text-indigo-600 mr-3" size={24} />
                <span className="text-gray-700 text-lg">Market Demand</span>
              </div>
              <span className="text-lg font-bold text-indigo-600">
                {evaluation.market_demand || "0/20"}
              </span>
            </div>

            {/* Feasibility */}
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <IconScale className="text-indigo-600 mr-3" size={24} />
                <span className="text-gray-700 text-lg">Feasibility</span>
              </div>
              <span className="text-lg font-bold text-indigo-600">
                {evaluation.feasibility || "0/20"}
              </span>
            </div>

            {/* Scalability */}
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <IconLayersDifference
                  className="text-indigo-600 mr-3"
                  size={24}
                />
                <span className="text-gray-700 text-lg">Scalability</span>
              </div>
              <span className="text-lg font-bold text-indigo-600">
                {evaluation.scalability || "0/20"}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <IconTrophy className="text-indigo-600 mr-3" size={24} />
                <span className="text-gray-700 text-lg">Competitive</span>
              </div>
              <span className="text-lg font-bold text-indigo-600">
                {evaluation.competitive_advantage || "0/20"}
              </span>
            </div>

            {/* Revenue Model */}
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <IconCashBanknote className="text-indigo-600 mr-3" size={24} />
                <span className="text-gray-700 text-lg">Revenue Model</span>
              </div>
              <span className="text-lg font-bold text-indigo-600">
                {evaluation.revenue_model || "0/20"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function AuthenticationPage() {
  return (
    <QueryProvider>
      <Layout>
        <UserProvider allowUnauthenticated>
          <Routes>
            <Route path="/ai_validation_tool" element={<IdeaValidation />} />
          </Routes>
        </UserProvider>
      </Layout>
    </QueryProvider>
  );
}
